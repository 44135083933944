/* WelcomeMessage.css */
/* WelcomeMessage.css */
.centerText {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 20px 0 20px; /* Adds top padding along with existing padding */
  text-align: center; /* Ensures text is centered if it wraps */
}
.centeredSpan {
}
.color-blue {
  background-color: #3f51b5; /* Blue background */
}

.white-text {
  color: #ffffff; /* White text */
}
