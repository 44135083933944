.galaxyProfileCover {
  height: 320px;
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  /* flex-direction: column; */
}
.adOverlay {
  position: relative; /* This makes the adOverlay a positioning context for absolutely positioned children */

  display: flex;
  justify-content: center;
  align-items: center;
  height: 51vh; /* This will make the overlay take up the full height of the viewport */
  width: 99%; /* This will make the overlay take up the full width of its parent */
  flex-direction: column;
}
.centerContentAd {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%; /* Ensure the container takes full height */
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fadeIn {
  animation-name: fadeIn;
  animation-duration: 2s;
}

.fadeOut {
  animation-name: fadeOut;
  animation-duration: 2s;
}
.countdown {
  margin-top: 5px;
  position: absolute;
  top: -9px;
  /* right: 10px; */
  /* transform: translate(11%, -39%); */

  padding: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 5px;
  font-size: 15px;
}
/* @media (max-width: 600px) {
  .adOverlay {
    flex-direction: column;
  }
} */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 70%;
  z-index: 10000; /* Higher than the z-index of the iframe */
}
.no-overlay {
  display: none;
}
.overlay.mmenu-active {
  pointer-events: none;
}
/* .galaxyProfileCoverIframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
} */
.youTubeUrlInput {
  height: 30px;
  border-radius: 10px;
  /* border: 1px solid gray; */
  border: none;
  font-size: 18px;
  padding-left: 5px;
  margin-right: 5px;
  outline: none;
  background: var(--bg-forth);
  /* margin-bottom: 20px; */
}

.galaxyProfileCoverImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
  border-radius: 0;
}
.centered-paragraph {
  display: flex;
  justify-content: center;
  padding: 0 50px;
  margin-bottom: 10px;
}
.galaxyProfileInfoName {
  margin-top: 10px;
  font-size: 1.5em; /* Adjust to match h4 size */
  font-weight: bold; /* Adjust to match h4 weight */
  margin-bottom: 5px; /* Reduce space below the name */
  display: flex;
  align-items: center;
}
.galaxyValueRow {
  display: flex;
  align-items: center;
  margin-bottom: 10px; /* Add space below the jgkTokenSum */
}
.galaxyJGKValue {
}
.galaxyValueLabel {
  margin-right: 5px;
}
.fieldName {
  font-weight: bold;
  margin-right: 5px;
  color: #555;
}
.galaxyProfileGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.showVideoAdRotator {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
}
.galaxyProfileImages {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 5px;
}

.galaxyProfileImages img {
  width: 50px;
  height: 50px;
  object-fit: cover;
}
.profileContainer {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  align-items: stretch;
  text-align: center;
}
.leftColumn,
.rightColumn,
.profileInfo {
  flex: 1;
}
.leftColumn,
.rightColumn {
  overflow: hidden;
}
.galaxyProfileInfoDesc {
  font-weight: 300;
  text-align: center;
  width: fit-content; /* This makes the container's width proportional to its content */
}
.galaxyProfileInfoDesc input {
  width: 100%; /* This makes the input field take up the full width of its parent container */
}
.easyEditWrapper {
  margin-bottom: 5px;
}
.centeredContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
}
.roleTitle {
  font-weight: bold;
}
.galaxyProfileOwnersProfileImg {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
  pointer-events: none;
  margin: 0 auto;
}
.galaxyProfileImages a {
  display: inline-block;
}
.fieldContainer {
  display: flex;
  align-items: center;
}
.attributesContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 50px;
}

.fieldContainer {
  display: flex;
  align-items: center;
}
.gridContainer {
  height: 45vh;
  display: grid;
  grid-template-columns: 1.5fr 1fr; /* This makes the left column twice as large as the right column */
  gap: 20px; /* This adds some space between the columns */
  /* max-height: 378px; */
}
.gridContainerTall {
  height: 74vh;
  display: grid;
  grid-template-columns: 1.5fr 1fr; /* This makes the left column twice as large as the right column */
  gap: 20px; /* This adds some space between the columns */
  /* max-height: 378px; */
}
.galaxyProfileTooltip {
  position: relative;
  display: inline-block;
  padding-left: 12px;
  transform: translate(-11px, -5px);
}

.galaxyProfileTooltip .galaxyProfileTooltiptext {
  visibility: hidden;
  width: 160px;
  top: 100%;
  left: 50%;
  margin-left: -80px;
  font-size: 12px;
  font-weight: 400;
  background-color: #fff;
  color: #333;
  box-shadow: 0 2.4rem 4.8rem rgba(0, 0, 0, 0.075);
  border-radius: 6px;
  padding: 5px;

  /* Position the galaxyProfileTooltip */
  position: absolute;
  z-index: 1;

  opacity: 0;
  transition: opacity 0.3s;
}
.buttonContainer {
  display: flex;
  align-items: center;
  gap: 10px; /* Adjust the gap as needed */
}
.galaxyProfileTooltip:hover .galaxyProfileTooltiptext {
  visibility: visible;
  opacity: 1;
}
.leftColumn {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.galaxyChatButtonDiv {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
}

.galaxyChatButton,
.galaxyChatButton:link,
.galaxyChatButton:visited {
  height: 38px;
  border-radius: 10px;
  border: none;
  background-color: #8a2be2;
  color: white;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  width: 70px;
  margin-top: 3px;
  transition: all 0.3s;
}

.galaxyChatButton:hover,
.galaxyChatButton:active {
  background-color: #639;
}

.galaxyChatBoxContainer {
  display: flex;
  flex-direction: column;
  height: 100%;

  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  margin: 10px 0 10px 10px; /* Added left margin of 20px */
  overflow-y: auto;
  /* margin-right: 20px; Adjust this value as needed */
}
.feedComponent {
  width: 60%; /* Adjust as needed */
}
.galaxyFeed {
  width: 100% !important;
}

.centerColumn {
  /* Style for center column */
}
.galaxyProfileImagesCentered {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  padding: 0 20px;
}

.requestContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: -21px; /* less space above DisplayPlayerJGK */
}
.headerSpacing {
  margin-top: 10px; /* adjust as needed */
  margin-bottom: 5px;
}
.mediaCoverContainer,
.youtubeInputContainer {
  width: 100%;
}
.requestContainer > button {
  margin-top: 10px;
}
.buttonContainer {
  display: flex;
  justify-content: space-between;
  margin-top: -7px; /* less space above buttons */
  margin-bottom: 10px; /* more space below buttons */
}
@media (max-width: 1280px) {
  .adOverlay {
    height: 37vh;
    /* width: 91%; */
  }
}
@media (max-width: 1024px) {
  .adOverlay {
    height: 47vh;
    /* width: 100%; */
  }
}
@media (max-width: 979px) {
  .attributesContainer {
    padding: 0 15px;
  }
  .adOverlay {
    height: 63vh;
    /* width: 33%; */
  }
}
@media (max-width: 912px) {
  .adOverlay {
    height: 62vh;
    /* width: 16%; */
  }
}
@media (max-width: 883px) {
  .galaxyProfileInfoName {
    font-size: 1.2em;
  }
}
@media (max-width: 806px) {
  .galaxyRightBar {
    display: none;
  }
  .gridContainer {
    grid-template-columns: 1fr; /* This makes the layout a single column */
    height: 85vh;
    /* position: relative; */
  }
  .leftColumn {
    order: 1;
  }
  .rightColumn {
    order: 2;
  }

  .leftColumn {
    width: 97%; /* This makes the columns take up the full width of their parent container */
  }
  .rightColumn {
    width: 100%; /* This makes the columns take up the full width of their parent container */
  }
  .gridContainer {
    flex-direction: column;
  }

  .leftColumn {
    order: 2;
  }

  .rightColumn {
    order: 1;
  }
  .adOverlay {
    height: 64vh;
    /* width: 39%; */
  }
}
/* .fading {
  transition: opacity 1s ease-in-out;
  opacity: 0.5;
} */
.show-more-link {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
  margin-top: auto; /* Adjust this value based on your needs */
}
@media (max-width: 1024px) {
  .gridContainerTall {
    height: 67vh;
  }
}
@media (max-width: 820px) {
  .adOverlay {
    height: 53vh;
    /* width: 57%; */
  }
}
@media (max-width: 806px) {
  /* Adjust this value based on your mobile breakpoint */
}
@media (max-width: 800px) {
  .adOverlay {
    height: 35vh;
    /* width: 45%; */
  }
}
@media (max-width: 768px) {
  .adOverlay {
    height: 53vh;
    /* width: 53%; */
  }
}
@media (max-width: 768px) {
  .galaxyProfileGrid {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 712px) {
  .adOverlay {
    height: 39vh;
    /* width: 48%; */
  }
}
@media (max-width: 705px) {
  .fieldName {
    margin-right: 50px;
  }
}
@media (max-width: 640px) {
  .adOverlay {
    height: 120vh;
    /* width: 48%; */
  }
  .gridContainer {
    height: 222vh;
  }
}
@media (max-width: 628px) {
  .adOverlay {
    height: 64vh;
    /* width: 59%; */
  }
}
@media (max-width: 600px) {
  .adOverlay {
    height: 46vh;
    /* width: 42%; */
  }
  .gridContainer {
    height: 113vh;
  }
}

@media (max-width: 599px) {
  .adOverlay {
    height: 64vh;
    /* width: 40%; */
  }
}
@media (max-width: 573px) {
  .fieldName {
    margin-right: 18px;
  }
}
@media (max-width: 540px) {
  .adOverlay {
    height: 65vh;
    /* width: 57%; */
  }
  .gridContainer {
    height: 134vh;
  }
}
@media (max-width: 538px) {
  .adOverlay {
    height: 53vh;
    /* width: 57%; */
  }
}
@media (max-width: 430px) {
  .adOverlay {
    height: 48vh;
    /* width: 71%; */
  }
}
@media (max-width: 418px) {
  .adOverlay {
    height: 50vh;
    /* width: 60%; */
  }
}
@media (max-width: 414px) {
  .adOverlay {
    height: 60vh;
    /* width: 93%; */
  }
}
@media (max-width: 412px) {
  .adOverlay {
    height: 47vh;
    /* width: 70%; */
  }
  .gridContainer {
    height: 151vh;
  }
}
@media (max-width: 410px) {
  .adOverlay {
    height: 43vh;
    /* width: 69%; */
  }
}
@media (max-width: 393px) {
  .adOverlay {
    height: 55vh;
    /* width: 77%; */
  }
}
@media (max-width: 390px) {
  .adOverlay {
    height: 53vh;
    /* width: 81%; */
  }
}
@media (max-width: 390px) {
  .fieldName {
    margin-right: 3px;
  }
}
@media (max-width: 384px) {
  .adOverlay {
    height: 65vh;
    /* width: 74%; */
  }
  .gridContainer {
    height: 162vh;
  }
}
@media (max-width: 375px) {
  .adOverlay {
    height: 64vh;
    /* width: 76%; */
  }
}
@media (max-width: 360px) {
  .adOverlay {
    height: 74vh;
    /* width: 100%; */
  }
}

@media (max-width: 359px) {
  .fieldName {
    margin-right: -15px;
  }
}
@media (max-width: 353px) {
  .adOverlay {
    height: 57vh;
    /* width: 75%; */
  }
}
@media (max-width: 360px) {
  .fieldName {
    margin-right: -15px;
  }
  .gridContainer {
    height: 162vh;
  }

  .responsive-container {
    width: 100%; /* Take up the full width of the parent */
    padding: 0 10px; /* Add some padding on the left and right to create some space */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
    overflow-x: hidden; /* Hide horizontal overflow */
  }
}
@media (max-width: 344px) {
  .adOverlay {
    height: 45vh;
    /* width: 74%; */
  }
}
@media (max-width: 320px) {
  .adOverlay {
    height: 80vh;
    /* width: 98%; */
  }
  .gridContainer {
    height: 252vh;
  }
}
@media (max-width: 240px) {
  .searchInputMobile {
    width: 5%;
    font-size: 12px;
  }
  .adOverlay {
    height: 134vh;
    /* width: 100%; */
  }
  .gridContainer {
    height: 482vh;
  }
}
