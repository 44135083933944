/* Unique class for the feedback form */
.uniqueFeedbackForm {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* General form styling */
.uniqueFeedbackForm form {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Form elements styling */
.uniqueFeedbackForm form div {
  margin-bottom: 15px;
}

.uniqueFeedbackForm label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

/* Specific styling for feedback form elements */
.uniqueFeedbackForm .feedback-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
}

.uniqueFeedbackForm textarea.feedback-input {
  resize: vertical;
  min-height: 100px;
}

.uniqueFeedbackForm button[type='submit'] {
  width: 100%;
  padding: 10px;
  background-color: #8a2be2;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.uniqueFeedbackForm button[type='submit']:hover {
  background-color: #5a189a;
}

.feedbackMessage {
  text-align: center;
  background-color: #f0f0f0;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  font-weight: bold;
  width: 50%;
}
