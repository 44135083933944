.send_verificationLink {
  background: var(--bg-primary);
  -webkit-box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  padding: 30px;
  font-size: 14px;
  color: var(--color-primary);
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  margin-bottom: 20px;
  margin-top: 70px;
}
.send_verificationLink a {
  color: var(--blue-color);
  cursor: pointer;
}
.send_verificationLink a:hover {
  text-decoration: underline;
}

.success_text {
  color: var(--green-color);
}

.error_text {
  color: #b94a48;
}
