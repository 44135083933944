html,
body {
  overflow-x: hidden;
}

.feed {
  flex: 5.5;
  /* margin-left: 1%; */

  width: 100%;
}

.feedWrapperFeed {
  padding: 7px;
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  /* Align items horizontally in the center */
  justify-content: flex-start; /* Align items vertically from the start */
  gap: 0px; /* Adjust the gap as needed */
}

.feedAdRotatorContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 15px;
}

.feedPostProgress {
  align-content: center;
  transform: translateX(11rem);
  padding-top: 20px;
}
.friendshipFeedWrapper {
  background: var(--bg-primary);
  -webkit-box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  padding: 30px;
  font-size: 14px;
  color: var(--color-primary);
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  margin-bottom: 20px;
}
.friendshipFeed {
  transform: translateX(17rem);
  padding-bottom: 5px;
  align-items: center;
  justify-content: center;
}

.feedFriendCount {
  /* transform: translateX(8rem); */
  text-align: center;
  font-weight: 600;
}

.feedFriendImgs {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 10px;
}
.feedFriendImgs2 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.feedOnlineFriendMsg {
  padding-left: 15px;
  padding-bottom: 10px;
  padding-right: 10px;
}
.feedChatOnlineImgContainer {
  position: relative;
  padding-bottom: 10px;
}

.feedFriendImgs img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  box-shadow: 0 1px 2px var(--shadow-1);
}
.feedChirbichawImgs img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: 10px;
  width: 17%;
  /* height: 32px; */
  border-radius: 5%;
  /* box-shadow: 0 1px 2px var(--shadow-1); */
}
.feedLilDImgs img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: -10px;
  width: 14%;
  /* height: 32px; */
  border-radius: 5%;
  /* box-shadow: 0 1px 2px var(--shadow-1); */
}
.feedLilDImgs img:hover {
  filter: brightness(140%);
}
.feedChatOnlineBadge {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: limegreen;
  position: absolute;
  top: 2px;
  right: 7px;
}
.feedChatOnlineBadgeMsg {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: limegreen;
  position: absolute;
  top: 2px;
  right: 10px;
}

.feedChatOnlineBadgeMobile {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: limegreen;
  position: absolute;
  top: 2px;
  right: 9px;
}
.feedChatOnlineBadgeMobileMsg {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: limegreen;
  position: absolute;
  top: 2px;
  right: 10px;
}

.feedFriendImgs img:hover {
  filter: brightness(110%);
}
.feedChirbichawImgs img:hover {
  filter: brightness(140%);
}

.viewPosts {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  color: #333;

  transition: all 0.3s;
}
.viewPostsMobile {
  margin-top: 10px;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  color: #333;

  transition: all 0.3s;
}

.viewPosts:hover {
  color: #8a2be2;
}

.lilDImgFeed {
  margin-top: 80px;
  margin-left: 10px;
  width: 70%;
  /* max-height: 500px; */
  height: 50%;
  object-fit: contain;
  pointer-events: none;
}
.btc_ImgFeed {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  pointer-events: none;
  /* margin-top: 54px; */
  /* margin-right: 40px; */
}
.LeaderBoardTitle {
  margin-top: 5px;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: -28px;
}
.audioDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}
.memePostToggle {
  /* display: flex;
  align-items: center;
  gap: 10px;
  align-items: center;
  justify-content: center; */
  /* padding-bottom: 5px; */
}
.memePostToggleMobile {
  display: flex;
  align-items: center;
  gap: 10px;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
}
.postTitle {
  margin-top: 15px;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 5px;
}

.postTitleThrowback {
  margin-top: 25px;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: -10px;
}
.centerContentFeed {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh;  */
}
.postButtonRank,
.postButtonRank:link,
.postButtonRank:visited {
  height: 50px;
  border-radius: 10px;
  border: none;
  background-color: #8a2be2;
  color: white;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  width: 100px;
  margin-top: 3px;
  transition: all 0.3s;
}

.postButtonRank:hover,
.postButtonRank:active {
  background-color: #639;
}
.postLeftButtonRank,
.postLeftButtonRank:link,
.postLeftButtonRank:visited {
  height: 50px;
  border-radius: 10px;
  border: none;
  background-color: #450b7a;
  color: white;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  width: 100px;
  margin-top: 3px;
  transition: all 0.3s;
  border: none;
  outline: none;
  background-image: linear-gradient(to right, #8a2be2 50%, #639 50%);
  background-position: 0%;
  background-size: 200%;
  color: #fff;
}
.postLeftButtonRank:hover {
  background-position: 100%;
}

.postButton .postLeftButtonRank:hover,
.postLeftButtonRank:active {
  background-color: #639;
}
.spotifyIframe {
  padding-bottom: 10px;
}
.memeLinkItem {
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
}

.memeLinkItem:visited {
  color: white;
}
.memeLinkItem:link {
  color: white;
}

.refreshToUpdate {
  display: flex;
  justify-content: center;
}

.refreshToUpdateButton {
  height: 75px;
  border-radius: 10px;
  border: none;
  background-color: #8a2be2;
  /* background-image: linear-gradient(to right, #8a2be2 50%, #639 50%);
  background-position: 0%;
  background-size: 200%; */

  color: white;
  font-size: 19px;
  font-weight: 500;
  cursor: pointer;
  width: 250px;
  margin-top: 3px;
  transition: all 0.3s;
}

/* @media (max-width: 1296px) {
  .feed {
    width: 50%;
  }
} */
@media (max-width: 1280px) {
  .btc_wrapper {
    margin-bottom: -60px;
  }
  .feedLilDImgs img {
    margin-bottom: -11px;
    width: 13%;
  }
}

@media (max-width: 912px) {
  .feedAdRotatorContainer {
    padding-right: 750px;
  }

  .btc_wrapper {
    margin-bottom: -60px;
  }
  .feedLilDImgs img {
    margin-bottom: -11px;
    width: 8%;
  }
}
@media (max-width: 1024px) {
  .btc_wrapper {
    margin-bottom: -60px;
  }
  .feedLilDImgs img {
    margin-bottom: -11px;
    width: 8%;
  }
}

@media (max-width: 820px) {
  .feedPostProgress {
    transform: translateX(64rem);
  }
  .feedChatOnlineBadge {
    width: 15px;
    height: 15px;
  }
  .friendshipFeed {
    transform: translateX(14rem);
  }
}

@media (max-width: 820px) {
  .feedAdRotatorContainer {
    padding-right: 475px;
  }

  .feedPostProgress {
    transform: translateX(24rem);
  }
  .feed {
    width: 60%;
  }
  .btc_wrapper {
    margin-bottom: -60px;
  }
  .feedLilDImgs img {
    margin-bottom: -11px;
    width: 9%;
  }
}

@media (max-width: 805px) {
  .feed {
    width: 60%;
  }
}

@media (max-width: 800px) {
  .postTitle {
    font-size: 25px;
  }
}

@media (max-width: 768px) {
  .feedAdRotatorContainer {
    padding-right: 435px;
  }

  .feedPostProgress {
    transform: translateX(22rem);
  }
}
@media (max-width: 640px) {
  .postTitle {
    font-size: 20px;
  }
}
@media (max-width: 540px) {
  .feedAdRotatorContainer {
    padding-right: 156px;
  }

  .feedPostProgress {
    transform: translateX(15rem);
  }
  .friendshipFeed {
    transform: translateX(16rem);
  }
  .feedLilDImgs img {
    margin-bottom: -11px;
    width: 13%;
  }
}

@media (max-width: 414px) {
  .feedAdRotatorContainer {
    padding-right: 46px;
  }

  .feedPostProgress {
    transform: translateX(11rem);
  }
  .btc_wrapper {
    margin-bottom: -60px;
  }
  .feedLilDImgs img {
    margin-bottom: -11px;
    width: 17%;
  }
}

@media (max-width: 393px) {
  .feedPostProgress {
    transform: translateX(10.25rem);
  }
}
@media (max-width: 390px) {
  .feedPostProgress {
    transform: translateX(10.5rem);
  }
  .friendshipFeed {
    transform: translateX(18rem);
  }
}
@media (max-width: 384px) {
  .postTitle {
    font-size: 20px;
  }
}
@media (max-width: 375px) {
  .feedAdRotatorContainer {
    padding-right: 46px;
  }
  .btc_wrapper {
    margin-bottom: -60px;
  }
  .feedLilDImgs img {
    margin-bottom: -11px;
    width: 21%;
  }
  .feedPostProgress {
    transform: translateX(10rem);
  }
  .friendshipFeed {
    transform: translateX(18rem);
  }
}
@media (max-width: 360px) {
  .btc_wrapper {
    margin-bottom: -60px;
  }
  .feedLilDImgs img {
    margin-bottom: -11px;
    width: 18%;
  }
  .feedPostProgress {
    transform: translateX(9.75rem);
  }
}
@media (max-width: 320px) {
  .btc_wrapper {
    margin-bottom: -46px;
  }
}
@media (max-width: 280px) {
  .feedAdRotatorContainer {
    padding-right: 46px;
    margin-left: 72px;
  }

  .btc_wrapper {
    margin-bottom: -60px;
  }
  .feedLilDImgs img {
    margin-bottom: -11px;
    width: 26%;
  }
  .friendshipFeed {
    transform: translateX(17.5rem);
  }
  .feedPostProgress {
    transform: translateX(7rem);
  }
}
