.login {
  width: 100vw;
  height: 100vh;
  background-color: #f0f2f5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginWrapper {
  width: 70%;
  height: 70%;
  display: flex;
}

.loginLeft,
.loginRight {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.logoDescMobile {
  /* margin-top: 20px; */
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.logoDescMobile img {
  height: 90%;
  width: 100%;
}

.loginCenter {
  display: flex;
  justify-content: center;
}

.loginLeft {
  margin: auto 0;
  margin-right: 20px;
  width: 100px;
  align-items: center;
}

.loginLogo {
  font-size: 50px;
  font-weight: 800;
  color: #1775ee;
  margin-bottom: 10px;
}

.loginDesc {
  font-size: 24px;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
}

.loginBox {
  height: 300px;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.loginInput {
  height: 50px;
  border-radius: 10px;
  border: 1px solid gray;
  font-size: 18px;
  padding-left: 20px;
}

.loginInput:focus {
  outline: none;
}

.loginButton,
.loginButton:link,
.loginButton:visited {
  height: 50px;
  border-radius: 10px;
  border: none;
  background-color: #8a2be2;
  color: white;

  font-size: 20px;
  font-weight: 500;
  cursor: pointer;

  transition: all 0.3s ease-in;
}

.loginButton:hover,
.loginButton:active {
  background-color: #639;
  color: white;
}

.loginButton:focus {
  outline: none;
}

.loginButton:disabled {
  cursor: not-allowed;
}

.loginForgot,
.loginForgot:link,
.loginForgot:visited {
  display: flex;
  justify-content: center;
  color: #333;
  transition: all 0.3s ease-in;
}

.loginForgot:hover,
.loginForgot:active {
  color: #333;
}

.loginRegisterButton,
.loginRegisterButton:link,
.loginRegisterButton:visited {
  width: 1000%;
  padding-left: 20px;
  padding-right: 20px;
  align-self: center;
  height: 50px;
  border-radius: 10px;
  border: none;
  background-color: #42b72a;
  color: white;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;

  transition: all 0.3s ease-in;
}

.loginRegisterButton:hover,
.loginRegisterButton:active {
  background-color: #2e801d;
  color: white;
}

.tag {
  font-style: italic;
  font-size: 20px;
}

.forgotPassword {
  align-items: center;
}

/*---------Media queries -----------*/
@media (max-width: 1024px) {
}
@media (max-width: 806px) {
  .loginLeft {
    display: none;
  }
}
@media (min-width: 807px) {
  .logoDescMobile {
    display: none;
  }
}
@media (max-width: 384px) {
  .loginBox {
    padding: 6px;
  }
}

@media (max-width: 360px) {
  .loginBox {
    padding: 6px;
  }
}
@media (max-width: 344px) {
  .loginWrapper {
    width: 80%;
  }
}
@media (max-width: 320px) {
  .loginWrapper {
    width: 55%;
  }
  .loginBox {
    width: 84%;
    margin-right: -17px;
    margin-left: -12px;
  }
  .loginDesc {
    font-size: 22px;
  }
}
@media (max-width: 280px) {
  .loginBox {
    width: 78%;
    margin-right: -17px;
    margin-left: -12px;
  }
  .loginDesc {
    font-size: 20px;
  }
}
/* @media (min-width: 850px) {
  .login {
    display: flex;
    align-items: center;
    max-width: 1000px;
    margin: 0 auto;
  }
  .loginLeft {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-bottom: 15vh;
  }
  .loginLeft img {
    width: 300px;
    margin-left: -1.7rem;
  }
  .loginLeft span {
    font-size: 28px;
  }
  .loginRight {
    width: 400px;
  }
  .input_wrap {
    width: 360px !important;
  }
} */
/* ---------------Media queries---------*/
