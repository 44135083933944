.rld-card__wrapper {
  background: #450b7a;
  border-radius: 8px;
  padding: 16px; /* Add padding as needed */
  /* margin-top: 10px; */
  color: white;
  margin: 5px;
}
.form-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.label,
.phone-input,
.loginButton {
  margin-bottom: 16px; /* Add some space between elements */
  width: 100%; /* Ensure elements take full width of the container */
}

.input-container {
  padding: 8px; /* Add padding to the input */
}
.topup-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 16px;
}

.entry {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
}
.phone-input-container {
  width: 100%;
}

.phone-input {
  width: 100%;
  padding: 8px; /* Add padding to the input */
}
/* Dropdown container for the country list */
.react-tel-input .country-list {
  max-height: 200px; /* Adjust the height as needed */
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* Each country item in the list */
.react-tel-input .country-list .country {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  cursor: pointer;
}

/* Flag and country name alignment */
.react-tel-input .country-list .country .flag {
  margin-right: 8px;
}

.react-tel-input .country-list .country .country-name {
  flex-grow: 1;
  text-align: left;
}

/* Hover effect for country items */
.react-tel-input .country-list .country:hover {
  background-color: #f0f0f0;
}
/* Selected country item */
.react-tel-input .selected-flag {
  display: flex;
  align-items: center;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}
.button-margin {
  margin-right: 10px;
}
.airtimeSubmitButton {
  width: 70px;
  height: 40px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #8a2be2;
  color: white;
}
.airtimeSubmitButtonDisabled {
  width: 70px;
  height: 40px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #450b7a;
  color: white;
}
.button-container {
  display: flex;
  gap: 10px; /* Adjust the gap as needed */
}

.button-margin {
  margin: 0; /* Remove any existing margin if necessary */
}
.parent-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100%; /* Ensure the parent container takes full height */
}

.button-container {
  display: flex;
  gap: 10px; /* Adjust the gap as needed */
}

.button-margin {
  margin: 0; /* Remove any existing margin if necessary */
}
.operator-logo {
  width: auto; /* Maintain the aspect ratio */
  height: auto; /* Maintain the aspect ratio */
  max-width: 100%; /* Ensure the image does not exceed the container's width */
  max-height: 100%; /* Ensure the image does not exceed the container's height */
  display: block; /* Remove any inline spacing */
  margin: 0 auto; /* Center the image horizontally if it's a block element */
}
.error_text_with_margin {
  margin-top: 10px; /* Adjust the value as needed */
}
