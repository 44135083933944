.jgkleaderboard {
  flex: 3;
  /* height: calc(100vh - 50px);
    overflow-y: scroll; */
  /* overflow-x: hidden; */
  position: sticky;
  top: 100px;
  /* width: 15vw; */
}
.jgkLeaderBoardTitle {
  font-size: 15px;
  font-weight: 200;
  margin-bottom: 0px;
  align-content: center;
}
.leaderBoardWrapper {
  padding: 10px;
}
.jgkleaderboardPlayerList {
  padding: 0;
  margin: 0;
  list-style: none;
  padding-bottom: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.viewTopPlayers {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  color: #333;
  transition: all 0.3s;
  /* padding-bottom: 5px; */
}
