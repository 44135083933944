.profile {
  display: flex;
}

.profileRight {
  flex: 9;
}

.profileCover {
  height: 320px;
  position: relative;
}
/* .coverImgChange {
  height: 320px;
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
} */
.profileCoverImg {
  width: 100%;
  height: 295px;
  object-fit: cover;
  object-position: center; /* Adjust this value as needed */
  cursor: pointer;
  border-radius: 0;
}

.profilePlayerImg {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 150px;
  border: 3px solid white;
  cursor: pointer;
  display: block;
}

.profileChangeProgress {
  display: flex;
  width: 45%;
  margin: auto 0;
  float: right;
}

.profileInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.profileInfoName {
  font-size: 24px;
}

.profileInfoDesc {
  font-weight: 300;
}

.profileRightBottom {
  display: flex;
}

.shareOptionProfile {
  display: flex;
  align-items: center;
  margin-right: 15px;
  cursor: pointer;
  justify-content: center;
  position: relative;
  z-index: 1000;
  top: 25px;
  right: -55px;
}

.shareBottomProfile {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* .shareOption {
  display: flex;
  align-items: center;
  margin-right: 15px;
  cursor: pointer;
} */

.profileImgChange {
  display: 'flex';
  flex-direction: 'column';
  align-items: 'center';
  justify-content: 'center';
}

.coverImgChange {
  display: 'flex';
  flex-direction: 'column';
  align-items: 'center';
  justify-content: 'center';
}

/* ------------- responsive ------------- */
@media (max-width: 1295px) {
  /* .profile {
    width: 1260px;
  } */
}

@media (max-width: 790px) {
  /* .profileCoverImg {
    width: 92%;
  } */
}

@media (max-width: 764px) {
  .profileRight {
    width: 95%;
  }
}
@media (max-width: 705px) {
  .profilePlayerImg {
    transform: translateX(-3rem);
  }
  .profileInfoName {
    transform: translateX(-3rem);
  }
  .easy-edit-wrapper {
    transform: translateX(-3rem);
  }
}

@media (max-width: 572px) {
  .profilePlayerImg {
    transform: translateX(-1rem);
  }
  .profileInfoName {
    transform: translateX(-1rem);
  }
  .easy-edit-wrapper {
    transform: translateX(-1rem);
  }
}

@media (max-width: 412px) {
  .profilePlayerImg {
    transform: translateX(-1rem);
  }
  .profileInfoName {
    transform: translateX(-1rem);
  }
  .easy-edit-wrapper {
    transform: translateX(-1rem);
  }
}
@media (max-width: 390px) {
  .profilePlayerImg {
    transform: translateX(0rem);
  }
  .profileInfoName {
    transform: translateX(0rem);
  }
  .easy-edit-wrapper {
    transform: translateX(0rem);
  }
}
@media (max-width: 360px) {
  .profilePlayerImg {
    transform: translateX(0rem);
  }
  .profileInfoName {
    transform: translateX(0rem);
  }
  .easy-edit-wrapper {
    transform: translateX(1rem);
  }
}
