.register {
  width: 100vw;
  height: 100vh;
  background-color: #f0f2f5;
  display: flex;
  align-items: center;
  justify-content: center;
}
.registerMobile {
  /* width: 100vw;
  height: 100vh; */
  background-color: #f0f2f5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.registerCenter {
  display: flex;
  justify-content: center;
}

.registerWrapper {
  width: 70%;
  height: 70%;
  display: flex;
}
.registerWrapperMobile {
  width: 95%;
  height: 100%;
  display: flex;
  margin-right: 5px;
  margin-left: 5px;
  margin-top: 15px;
  margin-bottom: 5px;
}

.registerLeft,
.registerRight {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.registerLeft {
  margin: auto 0;
  margin-right: 20px;
  width: 100px;
  align-items: center;
}

.registerLogo {
  font-size: 50px;
  font-weight: 800;
  color: #8a2be2;
  margin-bottom: 10px;
}

.registerDesc {
  font-size: 24px;
  padding-bottom: 10px;
}

.registerBox {
  height: 700px;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.registerInput {
  height: 50px;
  border-radius: 10px;
  border: 1px solid gray;
  font-size: 18px;
  padding-left: 20px;
}

.registerInput:focus {
  outline: none;
}

.registerButton,
.registerButton:link,
.registerButton:visited {
  padding: 5px 0;

  height: 50px;
  border-radius: 10px;
  border: none;
  background-color: #8a2be2;
  color: white;

  font-size: 20px;
  font-weight: 500;
  cursor: pointer;

  transition: all 0.3s ease-in;
}

.registerButton:hover,
.registerButton:active {
  background-color: #639;
  color: white;
}
.loginForgot {
  text-align: center;
  color: #1775ee;
}

.loginRegisterButton,
.loginRegisterButton:link,
.loginRegisterButton:visited {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  align-self: center;
  height: 50px;
  border-radius: 10px;
  border: none;
  background-color: #42b72a;
  color: white;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;

  transition: all 0.3s ease-in;
}

.loginRegisterButton:hover,
.loginRegisterButton:active {
  background-color: #2e801d;
  color: white;
}

.tag {
  font-style: italic;
  font-size: 20px;
}

.info_icon {
  background-image: url('https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1660451860/Images/icons36_pr8wvd.png');
  background-size: auto;
  background-repeat: no-repeat;
  display: inline-block;
  width: 12px;
  height: 12px;
  background-position: 0 -60px;
}

.reg_col {
  position: relative;
  align-self: flex-start;
  margin-bottom: 10px;
  padding: 10px 4px;
}

.reg_line_header {
  display: flex;
  align-items: center;
  gap: 2px;
  font-size: 13px;
  color: var(--color-secondary);
}

.reg_line_header i {
  margin-top: 3px;
}

.reg_grid {
  margin-top: 5px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  height: 35px;
  width: 100%;
}

.reg_grid select {
  width: 90px;
  font-size: 16px;
  color: var(--color-primary);
  background: var(--bg-primary);
  border-radius: 5px;
  cursor: pointer;
}

.reg_grid label {
  width: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  color: var(--color-primary);
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid var(--bg-secondary);
  padding: 0 10px;
}

.reg_line {
  padding: 5px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.registerCenter {
  padding: 5px 0;
}

.reg_info {
  font-size: 11px;
  margin-top: 10px;
  color: (--color-secondary);
}

.reg_info span {
  color: var(--blue-color);
}

.privacyPolicy {
  font-size: 11px;
}

@media (max-width: 806px) {
  .registerLeft {
    display: none;
  }
}
@media (min-width: 807px) {
  .logoDescMobile {
    display: none;
  }
}
@media (max-width: 320px) {
  .registerBox {
    width: 76%;
    margin-left: 13px;
  }
  .reg_grid select {
    width: 66px;
  }
  .reg_info {
    font-size: 10px;
  }
  .loginDesc {
    font-size: 20px;
  }
  .logoDescMobile {
    margin-right: 27px;
  }
}

@media (max-width: 280px) {
  .registerBox {
    width: 76%;
  }
  .reg_grid select {
    width: 66px;
  }
  .reg_info {
    font-size: 10px;
  }
  .loginDesc {
    font-size: 20px;
  }
  .logoDescMobile {
    margin-right: 27px;
  }
}
