.adContainerElement2 {
  /* height: 300px; */
  display: flex;
  flex-direction: column;
  height: auto;
  min-width: 300px; /* Minimum width */
  max-width: 100%; /* Maximum width to take full available space */
  width: auto; /* Let the width adjust based on content */
  border-radius: 10px;
  margin: 30px 0;
}
.loadingContainer {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  width: 100%; /* Ensure it takes the full width of its parent */
  height: 100%; /* Ensure it takes the full height of its parent */
}
.ad-img2 {
  /* set img elements to be responsive */
  height: 100%;
  max-height: 460px;
  width: 100%;
  border-radius: 10px;
  /* [optional] To stretch image to fit container */
  object-fit: cover;
}
.adOverlay {
  position: relative;
}
.videoAd {
  height: 35vh;
  width: 100%;
}
.adOverlay::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80%;
  background: transparent;
}
/* .rightbarAd {
    width: 100%;
    border-radius: 10px;
    margin: 30px 0;
  } */

.CircleProgress {
  align-self: center;
}

.adByName {
  /* position: absolute;
    right: 40px; */
}

.adByNameContainer {
  width: 300px;
  height: auto;
  display: flex;
}

.profileLink:link,
active {
  text-decoration: none;
  font-size: 12px;
}

.adBottom {
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.adBottomLeft {
  display: flex;
  align-items: center;
}

.likeIcon {
  width: 24px;
  height: 24px;
  margin-right: 5px;
  cursor: pointer;
}

.adLikeCounter {
  font-size: 15px;
}

.adCommentText {
  cursor: pointer;
  font-size: 10px;
}

.adViews {
  font-size: 13px;
  font-weight: 400;
  margin: 0 10px;
  transform: translate(0px, -2px);
}
.adViewsMobile {
  font-size: 13px;
  font-weight: 400;
  margin: 0 10px;
  transform: translateY(-0.19rem);
}
