.playerSearchResults {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow: hidden;
  margin-top: 200px;
  overflow-y: auto;
  width: 60%;
  padding: 0 16px;
  max-height: 180px;
  background-color: #ffffff;
  cursor: pointer;
  border-radius: 30px;
  position: relative;
  z-index: 999;
}

.myFriendsToSharePostResult {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  margin-left: 5px;
  align-items: center;
  justify-content: space-around;
}

.playerSearchResultImg {
  display: flex;
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
  margin-right: 5px;
}
.playerSeachTopbarImg {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
  margin-right: 10px;
}

.searchedPlayersPlayerId {
  font-weight: 400;
  font-size: 15px;
}
.myFriendsToSharePostWrapper {
  display: flex;
}
.myFriendsToSharePostPlayersPlayerId {
  font-weight: 400;
  font-size: 15px;
  padding-right: 5px;
}

.ulollist {
  list-style: none;
  margin: 0;
  padding: 0;
  padding-right: 5px;
}

.lihover {
  color: rgb(248, 21, 82);
}

@media (max-width: 807px) {
  .playerSearchResults {
    margin-left: -900px;
    width: 146%;
    /* margin-top: 250px; */
    margin-top: 410px;
  }
}
