.topbarContainer {
  height: 50px;
  background-color: #8a2be2;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 9999;
}

.tip {
  background-color: #450b7a;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  max-width: 400px;
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.left-tip {
  left: 0;
  right: auto;
  bottom: auto;
  top: -48px;
  transform: translateX(-140%);
  max-width: 400px;
  width: 78px;
  padding: 10px 20px;
}
.left-tip::after {
  content: '';
  position: absolute;
  bottom: 10px; /* Position the arrow at the bottom */
  right: -10px; /* Position the arrow on the right side */
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #450b7a; /* Match the background color of the tip */
}
.tip p {
  margin: 0;
  font-size: 13px;
}

.tip button {
  background-color: white;
  color: #450b7a;
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
  margin-top: 10px;
  font-size: 14px;
}
.hint-text {
  font-size: 0.875rem; /* Smaller font size, adjust as needed */
  color: #666; /* Optional: Change the color to a lighter shade */
  display: block; /* Ensure it behaves like a block element */
  margin-top: 5px; /* Optional: Add some margin for spacing */
  max-width: 133px; /* Set a maximum width, adjust as needed */
  word-wrap: break-word; /* Ensure the text wraps within the max-width */
}

.tip button:hover {
  background-color: #f1f1f1;
}

.topbarContainerPlayerMap {
  height: 79px;
  /* width: 100%; */
  background-color: #8a2be2;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 9999;
}
.topbarContainerMobile {
  height: 150px;
  /* width: 1255px; */
  background-color: #8a2be2;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 9997;
}
.topbarContainerMobileChat {
  height: 150px;
  /* width: 1255px; */
  background-color: #8a2be2;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 9999;
}

.topbarContainerMobilejgkToken {
  height: 150px;
  /* width: 1255px; */
  background-color: #8a2be2;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 9997;
}
.topbarLeft {
  flex: 3;
}
.topbarLeftMobile {
  flex: 3;
  height: 150px;
}

.logo {
  font-size: 24px;
  margin-left: 20px;
  font-weight: bold;
  color: white;
  cursor: pointer;
}

.topbarCenter {
  flex: 4;
}

.searchbar {
  width: 100%;
  height: 30px;
  background-color: white;
  border-radius: 30px;
  display: flex;
  align-items: center;
}

.searchbarMobile {
  width: 100%;
  height: 60px;
  background-color: white;
  border-radius: 30px;
  display: flex;
  align-items: center;
}

.searchIcon {
  font-size: 20px !important;
  margin-left: 10px;
}
.searchIconMobile {
  font-size: 35px !important;
  margin-left: 10px;
}

.searchInput {
  border: none;
  /* width: 100%; */
}
.searchInputMobile {
  border: none !important;
  font-size: 25px;
  outline: none;
  /* width: 100%; */
}

.searchInput:focus {
  outline: none;
}

.chirbichawImgTopbar {
  margin-top: 4px;
  margin-left: 10px;
  width: 50%;
  /* max-height: 500px; */
  object-fit: contain;
  pointer-events: none;
}
.lilDImgTopbar {
  margin-top: 21px;
  margin-left: 10px;
  width: 39%;
  /* max-height: 500px; */
  object-fit: contain;
  pointer-events: none;
}
.RexImgTopbar {
  margin-top: 21px;
  margin-left: 10px;
  width: 39%;
  /* max-height: 500px; */
  object-fit: contain;
  pointer-events: none;
}

.topbarRight {
  height: 100%;
  flex: 4;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: white;
}
.topbarMiddleRight {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.topbarLink {
  margin-right: 10px;
  font-size: 14px;
  cursor: pointer;
  color: white;
}

.topbarIcons {
  display: flex;
}

.mmenuJGKToken {
  padding: 0 0.3rem;
  position: absolute;
  top: 287%;
  right: 0;
  width: 225px;
  border-radius: 10px;
  background: var(--bg-primary);
  box-shadow: 2px 2px 2px var(--shadow-1);
  user-select: none;
  padding: 10px;
}

.friendRequestPlayersSearchResults {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow: hidden;
  margin-top: 200px;
  overflow-y: auto;
  width: 25%;
  padding: 0 16px;
  max-height: 180px;
  background-color: #ffffff;
  cursor: pointer;
  border-radius: 30px;
  /* margin-top: 173px;
  margin-right: 50px; */
  transform: translateY(0rem);
  transform: translateX(3rem);
}

.topbarIconItem,
.topbarAdIconItem,
.topbarMemeIconItem {
  margin-right: 25px;
  cursor: pointer;
  position: relative;
}
.topbarIconItemMobile,
.topbarAdIconItemMobile,
.topbarMemeIconItemMobile {
  margin-left: 7px;
  margin-right: 10px;
  cursor: pointer;
  position: relative;
}

.topbarIconItem a:link,
.topbarIconItem a:visited,
.topbarMemeIconItem a:link,
.topbarMemeIconItem a:visited,
.topbarAdIconItem a:link,
.topbarAdIconItem a:visited {
  color: white;
}
.topbarIconItemMobile a:link,
.topbarIconItemMobile a:visited,
.topbarMemeIconItemMobile a:link,
.topbarMemeIconItemMobile a:visited,
.topbarAdIconItemMobile a:link,
.topbarAdIconItemMobile a:visited {
  color: white;
}

.topbarIconBadge {
  width: 15px;
  height: 15px;
  background-color: red;
  border-radius: 50%;
  color: white;
  position: absolute;
  top: -5px;
  right: -5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

.topbarImg {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
  cursor: pointer;
  pointer-events: none;
}
.topbarImgMobile {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-right: 30px;
  object-fit: cover;
  cursor: pointer;
  pointer-events: none;
}

.topbarJGLogoImg {
  margin-top: 12px;
  cursor: pointer;
  height: 100px;
  width: 225px;
  object-fit: scale-down;
  padding-left: 14px;
  padding-right: 10px;
}
.topbarJGLogoImgMobile {
  margin-top: 12px;
  cursor: pointer;
  height: 150px;
  width: 450px;
  object-fit: scale-down;
  padding-left: 14px;
  padding-right: 14px;
}

.white-color {
  color: white;
  margin-left: 15px;
}

/*-----Hovers-----*/
.hover1:hover {
  background: var(--bg-secondary);
}
.hover2:hover {
  background: var(--bg-third);
}
.hover3:hover {
  background: #d4d6da3a;
}

/*---Circles------*/
.small_circle {
  position: relative;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 11px;
  background: var(--bg-third);
  cursor: pointer;
}
/*---Circles------*/

/*---Header right-----*/

.header_right {
  display: flex;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
}
.profile_link {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  padding: 3px 10px 3px 6px;
  border-radius: 50px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  margin-right: 10px;
}
.profile_link img {
  width: 28.7px;
  height: 28.7px;
  border-radius: 50%;
  border: 1px solid #b0b3b8;
  transform: translateX(-1px);
}
.profile_link span {
  margin-bottom: 3px;
}
.circle_icon {
  position: relative;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  background: var(--bg-third);
  cursor: pointer;
}
.right_notification {
  position: absolute;
  top: -6px;
  right: -5px;
  background: #e41e3f;
  border-radius: 50px;
  padding: 1px 6px;
  font-size: 13px;
  color: #fff;
}
/*---Header right-----*/

/* User Menu */
/*-------User menu----*/

.mmenu {
  padding: 0 0.3rem;
  position: absolute;
  top: 361%;
  right: 0;
  width: 225px;
  border-radius: 10px;
  background: var(--bg-primary);
  box-shadow: 2px 2px 2px var(--shadow-1);
  user-select: none;
  padding: 10px;
  z-index: 9999;
}
.mmenu_header {
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 10px;
  gap: 10px;
}
.mmenu_header img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}
.mmenu_col {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.mmenu_col span:first-of-type {
  color: var(--color-primary);
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 1px;
}
.mmenu_col span:last-of-type {
  font-size: 13px;
}
.mmenu_main {
  padding: 10px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
}
.mmenu_splitter {
  width: 100%;
  height: 1px;
  background: var(--bg-third);
  margin-top: 5px;
}
.mmenu_span1 {
  font-size: 14px !important;
}
.mmenu_span2 {
  font-size: 12px !important;
  color: var(--color-secondary);
}
.mmenu_emailAddressTxt {
  max-width: 153px;
  white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
  white-space: -webkit-pre-wrap; /* Chrome & Safari */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  white-space: pre-wrap; /* CSS3 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  word-break: keep-all;
  white-space: normal;
}
.mmenu_item {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 5px;
  padding: 7px;
  font-weight: 600;
  font-size: 14px;
  border-radius: 10px;
}
.rArrow {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.absolute_wrap {
  padding: 0 0.3rem;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 360px;
  height: fit-content;
  border-radius: 10px;
  background: var(--bg-primary);
  box-shadow: 2px 2px 2px var(--shadow-1);
  user-select: none;
  padding: 10px;
}
.absolute_wrap .mmenu_item {
  margin-top: 0;
}
.absolute_wrap_header {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 700;
  font-size: 24px;
}

.absolute_wrap label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 50px;
  font-weight: 600;
  padding: 10px;
  cursor: pointer;
  border-radius: 10px;
}
.absolute_wrap label input {
  width: 20px;
  height: 20px;
}

/*-------User menu----*/

/* User Menu */

/* ------------- responsive ------------- */
@media (max-width: 1295px) {
  /* .topbarContainer {
    width: 1295px;
  } */
  .chirbichawImgTopbar {
    margin-top: 6px;
    /* margin-left: 10px; */
    width: 65%;
    /* max-height: 500px; */
    object-fit: contain;
    pointer-events: none;
  }
}

@media (max-width: 1024px) {
  .friendRequestPlayersSearchResults {
    width: 50%;
  }
}
@media (max-width: 979px) {
  .topbarIconItem {
    margin-right: 10px;
  }
}

@media (max-width: 979px) {
  .friendRequestPlayersSearchResults {
    width: 40%;
  }
}
@media (min-width: 805) {
  .topbarAdIconItem {
    display: none;
  }

  .topbarMemeIconItem {
    display: none;
  }
}

@media (max-width: 820px) {
  .lilDImgTopbar {
    margin-top: 8px;
    width: 57%;
  }
}
/* @media (max-width: 807px) {
  .topbarIconItem {
    display: none;
  }
  .topbarImgMobile {
    margin-right: 33px;
  }
  .circle_icon {
    margin-right: 100px;
  }
} */
@media (max-width: 806px) {
  .topbarAdIconItemMobile {
    display: none;
  }

  .topbarMemeIconItem {
    display: none;
  }

  .topbarContainerMobile {
    width: 100%;
  }

  .topbarLeftMobile {
    width: 33%;
  }
  .topbarCenter {
    width: 33%;
  }

  .topbarRight {
    width: 33%;
  }
  .topbarJGLogoImgMobile {
    height: 121px;
    width: 243px;
  }
  .searchbarMobile {
    width: 100%;
  }
  .searchInputMobile {
    width: 25%;
    font-size: 15px;
  }
  .labelSearch {
    width: 403%;
    height: 40%;
  }
  /* .MuiPopover-root {
    width: 65%;
  } */
}

@media (max-width: 805px) {
  .topbarIconItem {
    display: none;
  }
  .topbarImgMobile {
    margin-right: 33px;
  }
  .circle_icon {
    margin-right: 100px;
  }
}
@media (max-width: 768px) {
  .topbarIconItem {
    display: none;
  }
  .topbarImgMobile {
    margin-right: 33px;
  }
  .circle_icon {
    margin-right: 100px;
  }
}

@media (max-width: 722px) {
  .searchInputMobile {
    width: 23%;
  }
}

@media (max-width: 690px) {
  .topbarJGLogoImgMobile {
    /* padding-right: 50px; */
    height: 115px;
    width: 100px;
    object-fit: scale-down;
  }
  .topbarIcons {
    display: none;
  }
  .searchInputMobile {
    width: 20%;
    font-size: 16px;
  }
  .labelSearch {
    width: 1000%;
    height: 38%;
  }

  .searchIconMobile {
    font-size: 25px !important;
    margin-left: 5px;
  }
}

@media (max-width: 606px) {
  .searchInputMobile {
    width: 17%;
    font-size: 13px;
  }
}
@media (max-width: 540px) {
  .searchInputMobile {
    width: 15%;
    font-size: 10px;
  }
  .topbarImgMobile {
    margin-right: 33px;
  }
  .circle_icon {
    margin-right: 70px;
  }
}
@media (max-width: 466px) {
  .searchInputMobile {
    width: 13%;
    font-size: 13px;
  }
}
@media (max-width: 424px) {
  .searchInputMobile {
    width: 10%;
    font-size: 13px;
  }
}

@media (max-width: 414px) {
  .topbarImgMobile {
    margin-right: 33px;
  }
  .circle_icon {
    margin-right: 40px;
  }
}

@media (max-width: 375px) {
  .topbarImgMobile {
    margin-right: 33px;
  }
  .circle_icon {
    margin-right: 30px;
  }
}

@media (max-width: 280px) {
  .searchInputMobile {
    width: 7%;
    font-size: 12px;
  }
  .topbarLeftMobile {
    padding-right: 3px;
  }
  .topbarRight {
    padding-left: 3px;
  }
  .topbarImgMobile {
    margin-right: 33px;
  }
  .circle_icon {
    margin-right: 12px;
  }
}

.report_filled_icon {
  background-image: url(https://res.cloudinary.com/joegalaxy/image/upload/v1665363362/Images/icons15_otboyk.png);
  background-position: 0px -25px;
  background-size: auto;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  display: inline-block;
}
.settings_filled_icon {
  background-image: url(https://res.cloudinary.com/joegalaxy/image/upload/v1665366454/Images/icons16_lnf6wp.png);
  background-position: -21px -228px;
  background-size: auto;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  display: inline-block;
}
.settings_filled_icon {
  background-image: url(https://res.cloudinary.com/joegalaxy/image/upload/v1665366454/Images/icons16_lnf6wp.png);
  background-position: -21px -228px;
  background-size: auto;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  display: inline-block;
}
.help_filled_icon {
  background-image: url(https://res.cloudinary.com/joegalaxy/image/upload/v1665366639/Images/icons17_w5spdt.png);
  background-position: 0px -487px;
  background-size: auto;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  display: inline-block;
}
.dark_filled_icon {
  background-image: url(https://res.cloudinary.com/joegalaxy/image/upload/v1665366639/Images/icons17_w5spdt.png);
  background-position: 0px -340px;
  background-size: auto;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  display: inline-block;
}
.logout_filled_icon {
  background-image: url(https://res.cloudinary.com/joegalaxy/image/upload/v1665366639/Images/icons15_ns9ypc.png);
  background-position: 0px -46px;
  background-size: auto;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  display: inline-block;
}
.right_icon {
  background-image: url(https://res.cloudinary.com/joegalaxy/image/upload/v1665366642/Images/icons18_gk1yjy.png);
  background-position: -83px -13px;
  background-size: auto;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  display: inline-block;
  filter: invert(50%);
}
.ad_choices_icon {
  background-image: url(https://res.cloudinary.com/joegalaxy/image/upload/v1665370196/Images/icons6_zyvlcy.png);
  background-position: 0 -126px;
  background-size: auto;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  display: inline-block;
  transform: translateY(1px);
  -webkit-filter: invert(40%);
}
