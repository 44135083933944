.createMemeFeed {
  flex: 5.5;
}

.createMemeFeedWrapper {
  padding: 20px;
}

.createMemeShare {
  width: 100%;
  height: 420px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
}
.createMemeShareNoFile {
  width: 100%;
  height: 350px;
  border-radius: 10px;
}
.createMemeShareDarkTheme {
  width: 100%;
  height: 420px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 16px -8px rgba(255, 255, 255, 0.68);
  box-shadow: 0px 0px 16px -8px rgba(255, 255, 255, 0.68);
}
.createMemeShareNoFileDarkTheme {
  width: 100%;
  height: 350px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 16px -8px rgba(255, 255, 255, 0.68);
  box-shadow: 0px 0px 16px -8px rgba(255, 255, 255, 0.68);
}

.createMemeShareTop {
  display: flex;
  align-items: center;
}
.createMemeShareTopMobile {
  display: flex;
  align-items: center;
  height: 100px;
}

.createMemeShareWrapper {
  padding: 10px;
}

.createMemeInput {
  height: 50px;
  border-radius: 10px;
  border: 1px solid gray;
  font-size: 18px;
  padding-left: 20px;
  margin-bottom: 20px;
  width: 200px;
}

.memeButton,
.memeButton:link,
.memeButton:visited {
  height: 50px;
  border-radius: 10px;
  border: none;
  background-color: #8a2be2;
  color: white;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  width: 225px;

  transition: all 0.3s;
}

.memeButton:hover,
.memeButton:active {
  background-color: #639;
}

.createMemeImg {
  width: 100%;
  object-fit: cover;
  max-height: 150px;
}

.memeVid {
  margin-top: 20px;
  width: 100%;
  max-height: 150px;
  object-fit: contain;
  /* transform: translateX(-1.2rem); */
  transform: translateY(-1.1rem);
}

.shareVidContainer {
  padding: 0 20px 0px 20px;
  position: relative;
  transform: translateX(-1.3rem);
  /* transform: translateY(-0.2rem); */
}

.createMemeBox {
  height: 300px;
  padding: 15px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
}

.createMemeDesc {
  font-size: 24px;
  color: #333;
  width: 200px;
  margin: 0 auto;
}

.shareProfileImg {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
  pointer-events: none;
}
.memeProfileImg {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
  pointer-events: none;
}

.memeTooltip {
  position: relative;
  display: inline-block;
  padding-left: 5px;
}

.memeTooltip .memeTooltiptext {
  visibility: hidden;
  width: 200px;
  top: 100%;
  left: 50%;
  margin-left: -80px;
  font-size: 12px;
  font-weight: 400;
  background-color: #fff;
  color: #333;
  box-shadow: 0 2.4rem 4.8rem rgba(0, 0, 0, 0.075);
  border-radius: 6px;
  padding: 5px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;

  opacity: 0;
  transition: opacity 0.3s;
}

.memeTooltip:hover .memeTooltiptext {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 912px) {
  .createMemeShare {
    /* height: 600px; */
  }
}

@media (max-width: 820px) {
  .createMemeShare {
    /* height: 600px; */
  }
}

@media (max-width: 540px) {
  .createMemeDesc {
    width: 99px;
  }
}

@media (max-width: 446px) {
  .createMemeInput {
    width: 110px;
  }
  .memeButton {
    width: 140px;
  }
}

@media (max-width: 414px) {
  .createMemeShare {
    /* height: 600px; */
  }
  .createMemeFeedWrapper {
    padding: 10px;
  }
  .createMemeDesc {
    width: 51px;
    font-size: 18px;
  }
}

@media (max-width: 393px) {
  .createMemeDesc {
    width: 43px;
    font-size: 16px;
  }
}

@media (max-width: 390px) {
  .createMemeDesc {
    width: 43px;
    font-size: 16px;
  }
}

@media (max-width: 375px) {
  .createMemeShare {
    /* height: 555px; */
  }
  .createMemeDesc {
    width: 77px;
  }
  .createMemeBox {
    width: 48%;
  }
  .shareVidContainer {
    width: 100%;
  }
}

@media (max-width: 360px) {
  .createMemeDesc {
    width: 59px;
    font-size: 18px;
  }
}

@media (max-width: 280px) {
  .shareVidContainer {
    width: 100%;
  }
  .createMemeShare {
    /* height: 502px; */
  }
  .createMemeInput {
    width: 70px;
    font-size: 12px;
  }
  .memeButton {
    width: 95px;
    height: 36px;
  }
  .createMemeDesc {
    width: 35px;
    font-size: 12px;
  }
  .memeProfileImg {
    width: 40px;
    height: 40px;
  }
  .memeVid {
    width: 121%;
    transform: translateX(-1.2rem);
    transform: translateY(-1.4rem);
  }
}
