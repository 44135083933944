.snoozeButton {
  height: 75px;
  border-radius: 10px;
  border: none;
  background-color: #450b7a;
  color: white;
  font-size: 19px;
  font-weight: 500;
  cursor: pointer;
  width: 100px;
  margin-top: 3px;
  transition: all 0.3s;
}
