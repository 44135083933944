body,
html {
  color: #333;
  /* background-color: black; */
}

.rightbar {
  flex: 3.5;
  /* margin-left: 1%; */
}

.rightbarWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.birthdayContainer {
  display: flex;
  align-items: center;
}

.birthdayImg {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.birthdayText {
  font-weight: 300;
  font-size: 15px;
}

.rightbarAd {
  width: 100%;
  border-radius: 10px;
  margin: 30px 0;
}

/* .rightbarTitle {
  margin-bottom: 20px;
} */

.rightbarFriendList {
  padding: 0;
  margin: 0;
  list-style: none;
}

.rightbarTitle {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
}

.ownProfileTitlePadding {
  padding-top: 50px;
}

.rightbarInfo {
  margin-bottom: 30px;
}

.rightbarInfoItem {
  margin-bottom: 10px;
}

.rightbarInfoKey {
  font-weight: 500;
  margin-right: 15px;
  color: #555;
}

.rightbarInfoValue {
  font-weight: 300;
}

.rightbarFollowings {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.rightbarFollowing {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  cursor: pointer;
}

.rightbarFollowingImg {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
  pointer-events: none;
}

.rightbarFollowButton {
  margin-top: 30px;
  margin-bottom: 10px;
  border: none;
  background-color: #8a2be2;
  color: white;
  border-radius: 5px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

.rightbarFollowButton:focus {
  outline: none;
}

.containerElement {
  height: 300px;
  width: 250px;
}

.rightbarInfoContainer {
  display: flex;
  height: 2px;
}

.rightbarInfoContent {
  width: 50px;
  font-weight: 500;
  margin-right: 15px;
  color: #555;
}

.rightbarInfoRelationship {
  padding-top: 15px;
}

/* @media (max-width: 805px) {
  .homeRightbar {
    display: none;
  }
} */
