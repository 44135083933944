.share {
  width: 100%;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
}
.shareDarkTheme {
  width: 100%;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  box-shadow: 0px 0px 16px -8px rgba(255, 255, 255, 0.68);
}

.shareWrapper {
  padding: 10px;
}
.shareJG {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.shareJGName {
  padding-bottom: 10px;
}
.shareTop {
  display: flex;
  align-items: center;
}

.shareTopMobile {
  display: flex;
  align-items: center;
  height: 100px;
}

.shareProfileImg {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
  pointer-events: none;
}
.shareProfileImgMobile {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
  pointer-events: none;
}

.shareInput {
  border: none;
  width: 80%;
}
.shareInputMobile {
  border: none;
  width: 80%;
  font-size: 25px;
  outline: none;
}

.shareInput:focus {
  outline: none;
}

.shareHr {
  margin: 20px;
}

.shareBottom {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 1rem;
  /* padding: 0.5rem; */
}

.shareOptions {
  display: flex;
  margin-left: 20px;
}

.comment_error {
  padding: 0 !important;
  opacity: 1 !important;
}

.shareMessage_wrapMobile {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background: var(--bg-forth);
  border-radius: 50px;
  padding-right: 10px;
}
.shareJG_wrapMobile {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background: var(--bg-forth);
  border-radius: 50px;
  padding-right: 10px;
}
.shareMessage_wrapMobile input {
  outline: none;
  border: none;
  flex: 1;
  height: 80px;
  font-size: 30px;
  padding: 0 10px;
  color: var(--color-primary);
  background: transparent;
}
.shareJG_wrapMobile input {
  outline: none;
  border: none;
  flex: 1;
  height: 35px;
  font-size: 16px;
  padding: 0 10px;
  color: var(--color-primary);
  background: transparent;
}

/*----share error----*/
.shareError {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: var(--bg-primary);
  opacity: 0.9;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  z-index: 999;
  color: #b94a48;
  animation: anim 0.3s;
  border-radius: 5px;
  padding: 3rem;
}
.shareError_error {
  width: 90%;
  font-weight: 600;
  padding-bottom: 15px;
  padding-left: 16px;
}
/*----share error----*/

.shareOption {
  display: flex;
  align-items: center;
  margin-right: 15px;
  cursor: pointer;
}
.shareOptionMobile {
  display: flex;
  align-items: center;
  margin-right: 15px;
  cursor: pointer;
  font-size: 24px;
}
.shareJGText {
  align-items: center;
  display: flex;
  justify-content: center;
  padding-top: 10px;
}
.shareIcon {
  font-size: 18px;
  margin-right: 3px;
}
.shareIconMobile {
  font-size: 28px;
  margin-right: 3px;
}

.shareOptionText {
  font-size: 14px;
  font-weight: 500;
}
.shareOptionTextMobile {
  font-size: 35px;
  font-weight: 500;
}
.centeredLocation {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 10px; /* Optional: Add some margin for spacing */
}
.form-check {
  border: 0px solid #a9a9a9;
}
.clearLocation {
  margin-left: 10px;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.3s;
}

.clearLocation:hover {
  opacity: 1;
}
.tooltipPOGW {
  position: relative;
  display: inline-block;
  padding-left: 5px;
  transform: translate(-17px, -4px);
}
.tooltipPOGW .tooltiptextPOGW {
  visibility: hidden;
  width: 160px;
  top: 100%;
  left: 50%;
  margin-left: -80px;
  font-size: 12px;
  font-weight: 400;
  background-color: #fff;
  color: #333;
  box-shadow: 0 2.4rem 4.8rem rgba(0, 0, 0, 0.075);
  border-radius: 6px;
  padding: 5px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;

  opacity: 0;
  transition: opacity 0.3s;
}

.tooltipPOGW:hover .tooltiptextPOGW {
  visibility: visible;
  opacity: 1;
}
.shareButton,
.shareButton:link,
.shareButton:visited,
.shareButtonDisabled,
.shareButtonDisabled:link,
.shareButtonDisabled:visited {
  border: none;
  padding: 7px;
  border-radius: 5px;
  background-color: #42b72a;
  font-weight: 500;
  margin-right: 20px;
  cursor: pointer;
  color: white;
  transition: all 0.3s ease-in;
}

.shareButtonDisabled,
.shareButtonDisabled:link,
.shareButtonDisabled:visited {
  background-color: grey;
}

.shareButton:hover,
.shareButton:visited,
.shareButtonDisabled:hover,
.shareButtonDisabled:visited {
  background-color: #2e801d;
  color: white;
}

.shareButtonMobile,
.shareButtonMobile:link,
.shareButtonMobile:visited,
.shareButtonMobileDisabled,
.shareButtonMobileDisabled:link,
.shareButtonMobileDisabled:visited {
  border: none;
  padding: 7px;
  border-radius: 5px;
  background-color: #42b72a;
  font-weight: 500;
  margin-right: 20px;
  cursor: pointer;
  color: white;
  font-size: 35px;
}

.shareButtonMobileDisabled,
.shareButtonMobileDisabled:link,
.shareButtonMobileDisabled:visited {
  background-color: grey;
}

.shareButtonMobile:hover,
.shareButtonMobile:visited {
  background-color: #2e801d;
  color: white;
}

.shareImgContainer {
  padding: 0 20px 10px 20px;
  position: relative;
}

.shareVidContainer {
  padding: 0 20px 10px 20px;
  position: relative;
}

.shareImg {
  width: 100%;
  object-fit: cover;
}

.shareCancelImg {
  position: absolute;
  top: 0;
  right: 20px;
  cursor: pointer;
  opacity: 0.7;
}

.shareCancelVid {
  position: absolute;
  top: 0;
  right: 20px;
  cursor: pointer;
  opacity: 0.7;
}

.postVid {
  margin-top: 20px;
  width: 100%;
  max-height: 500px;
  object-fit: contain;
}

.share_jg_comment_input_wrap {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background: var(--bg-forth);
  border-radius: 50px;
  padding-right: 10px;
}

.share_jg_comment_input_wrap input {
  outline: none;
  border: none;
  flex: 1 1;
  height: 35px;
  padding: 0 10px;
  color: var(--color-primary);
  background: transparent;
}

.pogwCheckBox {
  margin: auto;
  color: #9c27b0;
}
.pogwDiv {
  margin: auto;
  /* color: #8a2be2; */
  font-size: 14px;
  font-weight: 500;
  max-width: 100px;
  white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
  white-space: -webkit-pre-wrap; /* Chrome & Safari */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  white-space: pre-wrap; /* CSS3 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  word-break: keep-all;
  white-space: normal;
}
@media (max-width: 1280px) {
  .share_jg_comment_input_wrap {
    width: 150%;
  }
}
/* @media (max-width: 1156px) {
  .tooltipPOGW {
    transform: translate(-42px, -16px);
  }
} */

/* @media (min-width: 1136px) {
  .tooltipPOGW {
    transform: translate(-21px, -16px);
  }
} */
@media (min-width: 1126px) and (max-width: 1234px) {
  .tooltipPOGW {
    transform: translate(-29px, -4px);
  }
}
@media (max-width: 1024px) {
  .share_jg_comment_input_wrap {
    width: 150%;
  }
  .tooltipPOGW {
    transform: translate(-16px, -4px);
  }
}
@media (max-width: 979px) {
  .share {
    width: 99%;
  }
}
@media (max-width: 912px) {
  .tooltipPOGW {
    transform: translate(-29px, -4px);
  }
}
@media (max-width: 805px) {
  .share {
    width: 100%;
  }

  .h1 {
    width: 60%;
  }
}

@media (max-width: 820px) {
  .tooltipPOGW {
    transform: translate(-29px, -5px);
  }
  .share_jg_comment_input_wrap {
    width: 150%;
  }
}
@media (max-width: 800px) {
  .shareMessage_wrapMobile input {
    font-size: 25px;
  }
  .shareMessage_wrapMobile {
    height: 75%;
  }
  .shareButtonMobile {
    font-size: 30px;
  }
}
@media (max-width: 768px) {
  .shareJG_wrapMobile input {
    font-size: 25px;
  }
}
@media (max-width: 768px) {
  .tooltipPOGW {
    transform: translate(-14px, -5px);
  }
  .shareMessage_wrapMobile {
    height: 55%;
  }
  .shareMessage_wrapMobile input {
    font-size: 24px;
  }
}
@media (max-width: 600px) {
  .shareMessage_wrapMobile input {
    font-size: 20px;
  }
  .memeRotatorContainerElementMobile {
    width: 555px;
  }
  .shareProfileImgMobile {
    width: 60px;
    height: 60px;
  }
  .shareButtonMobile {
    font-size: 25px;
  }
}
@media (max-width: 578px) {
  .shareOptionTextMobile {
    font-size: 25px;
  }
  .shareButtonMobile {
    font-size: 25px;
  }
}

@media (max-width: 540px) {
  .shareMessage_wrapMobile {
    height: 55%;
  }
  .shareMessage_wrapMobile input {
    font-size: 19px;
  }
}
@media (max-width: 534px) {
  .tooltipPOGW {
    transform: translate(-21px, 6px);
  }
}
@media (max-width: 520px) {
  .shareOptionTextMobile {
    font-size: 20px;
  }
  .shareButtonMobile {
    font-size: 14px;
  }
  .shareProfileImgMobile {
    width: 50px;
    height: 50px;
  }
}
@media (max-width: 430px) {
  .tooltipPOGW {
    transform: translate(-11px, 6px);
  }
}
@media (max-width: 414px) {
  .tooltipPOGW {
    transform: translate(-11px, -1px);
  }
  .shareMessage_wrapMobile {
    height: 55%;
  }
  .shareMessage_wrapMobile input {
    font-size: 13px;
  }
  .shareJG_wrapMobile input {
    font-size: 20px;
  }
  .shareJGText {
    padding-left: 34px;
  }
}

@media (max-width: 412px) {
  .shareButtonMobile,
  .shareButtonMobile:link,
  .shareButtonMobile:visited,
  .shareButtonMobileDisabled,
  .shareButtonMobileDisabled:link,
  .shareButtonMobileDisabled:visited {
    font-size: 21px;
  }
  .shareMessage_wrapMobile input {
    font-size: 12px;
  }
  .tooltipPOGW {
    transform: translate(-16px, -4px);
  }
}

@media (max-width: 393px) {
  .shareMessage_wrapMobile input {
    font-size: 12px;
  }
  .shareJG_wrapMobile input {
    font-size: 18px;
  }
  .shareJGText {
    padding-left: 29px;
  }
  .tooltipPOGW {
    transform: translate(-16px, -4px);
  }
}

@media (max-width: 390px) {
  .shareButtonMobile,
  .shareButtonMobile:link,
  .shareButtonMobile:visited,
  .shareButtonMobileDisabled,
  .shareButtonMobileDisabled:link,
  .shareButtonMobileDisabled:visited {
    font-size: 21px;
  }
  .chatMessage_wrapMobile input {
    font-size: 11px;
  }
  .shareMessage_wrapMobile input {
    font-size: 11px;
  }
  .shareJG_wrapMobile input {
    font-size: 19px;
  }
  .shareJGText {
    padding-left: 26px;
  }
  .tooltipPOGW {
    transform: translate(-22px, -4px);
  }
}
@media (max-width: 384px) {
  .shareOptions {
    margin-left: 8px;
  }
}
@media (max-width: 375px) {
  .shareMessage_wrapMobile {
    height: 55%;
  }
  .shareMessage_wrapMobile input {
    font-size: 10px;
  }
  .shareJGText {
    padding-left: 23px;
  }
  .tooltipPOGW {
    transform: translate(-14px, -4px);
  }
}

@media (max-width: 360px) {
  .tooltipPOGW {
    transform: translate(-16px, -4px);
  }
  .shareBottom {
    gap: 0rem;
  }
  .shareOptions {
    margin-right: 9px;
  }
  .shareButtonMobile,
  .shareButtonMobile:link,
  .shareButtonMobile:visited,
  .shareButtonMobileDisabled,
  .shareButtonMobileDisabled:link,
  .shareButtonMobileDisabled:visited {
    font-size: 14px;
  }
  .shareMessage_wrapMobile input {
    font-size: 9px;
  }
  .shareJG_wrapMobile input {
    font-size: 16px;
  }
  .shareJGText {
    padding-left: 26px;
  }
}
@media (max-width: 353px) {
  .shareJG_wrapMobile input {
    width: 177px;
  }
}
@media (max-width: 344px) {
  .shareButtonMobile,
  .shareButtonMobile:link,
  .shareButtonMobile:visited,
  .shareButtonMobileDisabled,
  .shareButtonMobileDisabled:link,
  .shareButtonMobileDisabled:visited {
    font-size: 14px;
  }
}
@media (max-width: 320px) {
  .shareOption {
    margin-right: 10px;
  }
  .shareOptions {
    margin-right: -18px;
  }
  .shareButtonMobile,
  .shareButtonMobile:link,
  .shareButtonMobile:visited,
  .shareButtonMobileDisabled,
  .shareButtonMobileDisabled:link,
  .shareButtonMobileDisabled:visited {
    font-size: 13px;
  }
  .shareProfileImgMobile {
    width: 30px;
    height: 30px;
  }
  .shareMessage_wrapMobile input {
    font-size: 8px;
  }
  .shareOptions {
    margin-left: 6px;
  }
  .shareJG_wrapMobile input {
    width: 171px;
  }
}
@media (max-width: 280px) {
  .shareButtonMobile,
  .shareButtonMobile:link,
  .shareButtonMobile:visited,
  .shareButtonMobileDisabled,
  .shareButtonMobileDisabled:link,
  .shareButtonMobileDisabled:visited {
    font-size: 9px;
  }
  .shareOptionTextMobile {
    font-size: 15px;
  }
  .shareButtonMobile {
    font-size: 15px;
  }
  .shareProfileImgMobile {
    width: 40px;
    height: 40px;
  }
  .shareMessage_wrapMobile {
    height: 55%;
    width: 75%;
  }
  .shareMessage_wrapMobile input {
    font-size: 8px;
  }
  .shareJG_wrapMobile input {
    font-size: 11px;
  }
  .shareJGText {
    padding-left: 25px;
  }
  .shareOptionText {
    font-size: 12px;
  }
  .tooltiptext .tooltip {
    width: 122px;
    left: 14%;
    top: 113%;
    margin-left: -87px;
  }
  .shareOptions {
    margin-left: 8px;
  }
}
@media (max-width: 240px) {
  .shareButtonMobile,
  .shareButtonMobile:link,
  .shareButtonMobile:visited,
  .shareButtonMobileDisabled,
  .shareButtonMobileDisabled:link,
  .shareButtonMobileDisabled:visited {
    font-size: 9px;
  }
  .shareJG_wrapMobile {
    width: 92%;
  }
  .comment_input_wrapMobile {
    width: 32%;
  }
  .shareOption {
    margin-right: 4px;
  }
}
