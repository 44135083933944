.advertiseContainer {
  display: flex;
  width: 100%;
}

/* ------------- responsive ------------- */
@media (max-width: 1295px) {
  .advertiseContainer {
    /* width: 1260px; */
  }
}

.adContainer {
}
.spacerForPage {
  padding-left: 25px;
  justify-items: center;
}

@media (max-width: 1092px) {
  .sidebar {
    display: none;
  }
}

@media (min-width: 805px) {
  /* .spacerForPage {
    display: none;
  } */

  /* .rightbar {
    display: none;
  } */
  /* .advertiseContainer {
    width: 75%;
    display: flex;
    align-items: center;
  } */
}

@media (max-width: 979px) {
  .createAdWrapper {
    width: auto; /* Adjust based on your content, or keep 100% if needed */
    max-width: 960px; /* Example max-width, adjust as necessary */
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  .adContainerElement {
    width: 439px;
    padding-left: 300px;
  }
}
@media (max-width: 907px) {
  .adContainerElement {
    width: 439px;
    padding-left: 200px;
  }
}
@media (max-width: 860px) {
  .adContainerElement {
    width: 439px;
    padding-left: 170px;
  }
}

@media (max-width: 805px) {
  .adContainerElement {
    padding-left: 150px;
  }
}
@media (max-width: 628px) {
  .adContainerElement {
    padding-left: 90px;
  }
}
@media (max-width: 558px) {
  .adContainerElement {
    padding-left: 40px;
  }
}
@media (max-width: 554px) {
  .adContainerElement {
    padding-left: 10px;
  }
}
@media (max-width: 492px) {
  .advertise img {
    height: auto;
    width: 60%;
  }
  .adContainerElement {
    padding-left: 45px;
  }
}

@media (max-width: 280px) {
  .spacerForPage {
    padding-left: 1px;
  }
  .advertise img {
    height: auto;
    width: 48%;
  }
}
