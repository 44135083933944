.jgktokenContainer {
  display: flex;
  width: 100%;
  height: 100% !important;
}

.jgkTokenBalance {
  /* transform: translateX(8rem); */
  padding-top: 10px;
  text-align: center;
  font-weight: 600;
}
.jgkTokenDescription {
  margin-top: 10px;
  font-size: 14px;
  color: #333;
  text-align: center;
}
.jgkTokenTop {
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: row;
  justify-content: center;
  padding-top: 20px;
  font-size: 20px;
}
.jgkTokenType {
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  padding-left: 10px;
}
.jgkCurrencyFormat {
  margin-left: 20px;
}
.jgkTokenBottom {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 93%;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  margin: 30px 21px;
}
.jgkTokenBottomDarkTheme {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 93%;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  box-shadow: 0px 0px 16px -8px rgba(255, 255, 255, 0.68);
  margin: 30px 21px;
}

.jgkTokenTable {
}
.jgkTokenWrapper {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.jgkTokenWrapperInfo {
  padding: 10px;
  width: 100%;
  max-width: 548px; /* Adjust as needed */
  /* display: flex; */
  /* align-items: center;
  justify-content: center; */
}
.closeButton {
  margin-top: 10px;
  padding: 8px 16px;
  font-size: 14px;
  color: #fff;
  background-color: #8a2be2;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.closeButton:hover {
  background-color: #450b7a;
}
.jgkPlayerId {
  font-size: 12px;
  font-weight: 500;
  margin: 0 10px;
}
.jgkPlayerIdMobile {
  font-size: 40px;
  font-weight: 500;
  margin: 0 10px;
}
.jgkTokenProfileImgMobile {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  pointer-events: none;
}

.jgkplayerIdTokenBuffer {
  margin-bottom: 10px;
}
.viewJGKTransactions {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  color: #333;

  transition: all 0.3s;
}

.currencyOutlineIcon {
  padding-top: 2px;
}

.tooltipJGK {
  position: relative;
  display: inline-block;
  padding-left: 5px;
}

.tooltipJGK .tooltipJGKtext {
  visibility: hidden;
  width: 160px;
  top: 100%;
  left: 50%;
  margin-left: -80px;
  font-size: 12px;
  font-weight: 400;
  background-color: #fff;
  color: #333;
  box-shadow: 0 2.4rem 4.8rem rgba(0, 0, 0, 0.075);
  border-radius: 6px;
  padding: 5px;

  /* Position the tooltipJGK */
  position: absolute;
  z-index: 1;

  opacity: 0;
  transition: opacity 0.3s;
}

.tooltipJGK:hover .tooltipJGKtext {
  visibility: visible;
  opacity: 1;
}

.jgkBuyButton,
.jgkBuyButton:link,
.jgkBuyButton:visited {
  height: 80px;
  border-radius: 10px;
  border: none;
  background-color: #8a2be2;
  color: white;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  width: 100px;
  margin-top: 3px;
  transition: all 0.3s;
}

.jgkBuyButton:hover,
.jgkBuyButton:active {
  background-color: #639;
}

.buttonDiv {
}
.jgkCashoutButton,
.jgkCashoutButton:link,
.jgkCashoutButton:visited {
  height: 80px;
  border-radius: 10px;
  border: none;
  background-color: #450b7a;
  color: white;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  width: 100px;
  margin-top: 3px;
  transition: all 0.3s;
}

.jgkCashoutButton:hover,
.jgkCashoutButton:active {
  background-color: #639;
}

.jgkCashoutBuyToggle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 15px;
}

/* Tooltip container */
.tooltipJGKType {
  position: relative;
  /* display: inline-block; */
  border-bottom: 1px dotted black;
  width: 50%;
  display: flex;
  /* If you want dots under the hoverable text */
}

/* Show the tooltip text when you mouse over the tooltip container */

/* ------------- responsive ------------- */
@media (min-width: 1295px) {
  .jgkCurrencyFormat {
    margin-left: 95px;
  }
}
@media (max-width: 1295px) {
  .advertiseContainer {
    /* width: 1260px; */
  }
  .jgkCurrencyFormat {
    margin-left: 90px;
  }
}

.adContainer {
}
/* .spacerForPage {
  padding-left: 25px;
  justify-items: center;
} */

@media (max-width: 1092px) {
  .sidebar {
    display: none;
  }
}

@media (max-width: 1024px) {
  .jgkPlayerId {
    font-size: 20px;
  }
  .jgkCurrencyFormat {
    margin-left: 95px;
  }
}
@media (min-width: 805px) {
  /* .spacerForPage {
      display: none;
    } */

  /* .rightbar {
      display: none;
    } */
  /* .advertiseContainer {
      width: 75%;
      display: flex;
      align-items: center;
    } */
}

@media (max-width: 979px) {
  .createJGTokenWrapper {
    width: 100%;
  }
  .adContainerElement {
    width: 439px;
    padding-left: 250px;
  }
  .jgkTokenBottom {
    width: 95%;
  }
}
@media (max-width: 955px) {
  .adContainerElement {
    padding-left: 300px;
  }
}
@media (max-width: 890px) {
  .adContainerElement {
    padding-left: 183px;
  }
}
@media (max-width: 912px) {
  /* .jgkCurrencyFormat {
    margin-left: 95px;
  } */
}

@media (max-width: 860px) {
  .adContainerElement {
    width: 439px;
    padding-left: 170px;
  }
}

@media (max-width: 820px) {
  .jgkPlayerId {
    font-size: 25px;
  }
  .jgkTokenBottom {
    width: 95%;
  }
  /* .jgkCurrencyFormat {
    margin-left: 46px;
  } */
}
@media (max-width: 805px) {
  .adContainerElement {
    padding-left: 150px;
  }
}
@media (max-width: 800px) {
  .jgkPlayerIdMobile {
    font-size: 30px;
  }
  .jgkTokenProfileImgMobile {
    width: 70px;
    height: 70px;
  }
}
@media (max-width: 795px) {
  .adContainerElement {
    padding-left: 120px;
  }
}
@media (max-width: 768px) {
  .jgkTokenProfileImgMobile {
    width: 60px;
    height: 60px;
  }
  .jgkTokenTop {
    margin-left: 20px;
  }
  .jgkPlayerIdMobile {
    font-size: 25px;
  }
}
@media (max-width: 700px) {
  .adContainerElement {
    padding-left: 90px;
  }
}
@media (max-width: 628px) {
  .adContainerElement {
    padding-left: 60px;
  }
}

@media (max-width: 554px) {
  .adContainerElement {
    padding-left: 35px;
  }
}
@media (max-width: 540px) {
  .jgkPlayerIdMobile {
    font-size: 20px;
  }
  .jgkTokenBottom {
    width: 93%;
  }
  .tooltipJGK .tooltipJGKtext {
    margin-left: -155px;
  }
  .adContainerElement {
    padding-left: 30px;
  }
  .ad-img {
    width: 83%;
  }
}
@media (max-width: 520px) {
  .adContainerElement {
    padding-left: 30px;
  }
}
@media (max-width: 492px) {
  /* img {
    height: auto;
    width: 60%;
  } */
  .adContainerElement {
    padding-left: 45px;
  }
}
@media (max-width: 480px) {
  .adContainerElement {
    padding-left: 24px;
  }
  .ad-img {
    width: 75%;
  }
}

@media (max-width: 449px) {
  .adContainerElement {
    padding-left: 24px;
  }
}
@media (max-width: 430px) {
  .adContainerElement {
    padding-left: 12px;
  }
}

@media (max-width: 414px) {
  .jgkTokenProfileImgMobile {
    width: 35px;
    height: 35px;
  }
  .jgkPlayerIdMobile {
    font-size: 13px;
  }
  .jgkTokenBottom {
    width: 91%;
  }
  .jgkCurrencyFormat {
    margin-left: 75px;
  }
  .tooltipJGK .tooltipJGKtext {
    margin-left: -155px;
  }
}
@media (max-width: 414px) {
  .adContainerElement {
    padding-left: 4px;
  }
  .ad-img {
    width: 50%;
  }
}
@media (max-width: 412px) {
  .ad-img {
    width: 55%;
  }
  .adContainerElement {
    padding-left: 10px;
  }
}
@media (max-width: 411px) {
  .adContainerElement {
    padding-left: 2px;
  }
  .ad-img {
    width: 60%;
  }
}
@media (max-width: 393px) {
  /* .jgkCurrencyFormat {
    margin-left: 19px;
  } */
  .adContainerElement {
    padding-left: 36px;
  }
}
@media (max-width: 390px) {
  .jgkPlayerIdMobile {
    font-size: 13px;
  }
  .jgkCurrencyFormat {
    margin-left: 70px;
  }
  .adContainerElement {
    padding-left: 5px;
  }
  .ad-img {
    width: 75%;
  }
}
@media (max-width: 375px) {
  .jgkTokenProfileImgMobile {
    width: 35px;
    height: 35px;
  }
  .jgkPlayerIdMobile {
    font-size: 11px;
  }
  .jgkTokenBottom {
    width: 91%;
  }
  .jgkCurrencyFormat {
    margin-left: 65px;
  }
  .ad-img {
    width: 64%;
  }
}
@media (max-width: 360px) {
  .jgkCurrencyFormat {
    margin-left: 60px;
  }
  .ad-img {
    width: 65%;
  }
  .adContainerElement {
    padding-left: 30px;
  }
}
@media (max-width: 320px) {
  .jgkTokenBottom {
    width: 88%;
  }
  .adContainerElement {
    padding-left: 50px;
  }
  .ad-img {
    width: 60%;
  }
  .ad-video {
    width: 55%;
    padding-left: 0px;
  }
}
@media (max-width: 280px) {
  .jgkPlayerIdMobile {
    font-size: 10px;
  }
  .jgkTokenBottom {
    width: 88%;
  }
  .adContainerElement {
    padding-left: 37px;
  }
  .jgkCurrencyFormat {
    margin-left: 30px;
  }
  .ad-img {
    width: 48%;
  }
}
@media (max-width: 240px) {
  .adContainerElement {
    padding-left: 30px;
  }
  .ad-img {
    width: 40%;
  }
}
