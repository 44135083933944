.adRotatorContainerElement2 {
  /* height: 300px; */
  display: block;
  height: auto;
  width: 300px;
  border-radius: 10px;
  margin: 30px auto;
}
.adRotatorSelfCentering {
  display: block; /* Use block layout for auto margins to work */
  max-width: 960px; /* Example max-width, adjust based on your content */
  margin: 0 auto; /* Centers the container within its parent */
  width: 100%; /* Ensures it takes up the full width of its parent up to max-width */
}
img {
  /* set img elements to be responsive */
  height: 100%;
  width: 100%;
  border-radius: 10px;
  /* [optional] To stretch image to fit container */
  object-fit: cover;
}

.adRotatorByNameContainer {
  width: 300px;
  height: auto;
}

.adRotatorProgress {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 15px;
}

.profileLink:link,
active {
  text-decoration: none;
  font-size: 12px;
}

@media (max-width: 1280px) {
  .adRotatorContainerElement {
    margin-bottom: 3px;
  }
}

@media (max-width: 1024px) {
  .adRotatorContainerElement {
    margin-bottom: 3px;
  }
}

@media (max-width: 912px) {
  .adRotatorContainerElement {
    margin-bottom: 3px;
  }
}

@media (max-width: 820px) {
  .adRotatorContainerElement {
    margin-bottom: 3px;
  }
}
@media (max-width: 600px) {
  .adRotatorContainerElement {
    width: 200px;
  }
}

@media (max-width: 430px) {
  .adRotatorContainerElement {
    width: 200px;
  }
  .shareMessage_wrapMobile input {
    font-size: 15px;
  }
}
@media (max-width: 414px) {
  .adRotatorContainerElement {
    margin-bottom: 3px;
  }
}
@media (max-width: 412px) {
  .adRotatorContainerElement {
    margin-left: 57px;
  }
}
@media (max-width: 393px) {
  .adRotatorContainerElement {
    width: 300px;
  }
}

@media (max-width: 375px) {
  .adRotatorContainerElement {
    margin-bottom: 3px;
  }
}

@media (max-width: 280px) {
  .adRotatorContainerElement {
    margin-bottom: 3px;
  }
}
