* {
  font-family: 'Rubik', sans-serif;
}

.sidebar {
  flex: 3;
  /* height: calc(100vh - 50px); */
  overflow-y: scroll;
  overflow-x: hidden;
  position: sticky;
  top: 50px;
  /* width: 15vw; */
}

::-webkit-scrollbar {
  width: 3px;
}
::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background-color: rgb(179, 179, 179);
}

.sidebarWrapper {
  padding: 30px;
}
.jgcolor-span {
  display: block;
  color: var(--bg-jgcolora);
  padding: 5px; /* Optional: Add padding for better visibility */
  border-radius: 3px; /* Optional: Add border-radius for rounded corners */
  font-weight: 600;
}

.sidebarList {
  padding: 0;
  margin: 0;
  list-style: none;
}

.sidebarListItem {
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
}
.sidebarListItemText {
}
.sidebarListItemTextDark {
  /* Base styles for dark mode text */
}

.sidebarListItemTextDark:hover,
.sidebarListItemTextDark a:link:hover,
.sidebarListItemTextDark a:visited:hover {
  /* background-color: #639; */
  color: #639;
}
.sidebarIcon {
  margin-right: 15px;
}
.sidebarIconDark {
  margin-right: 15px;
  color: white;
}
.sidebarIconTooltip {
  height: 10px;
  width: 10px;
}

.sidebarButton {
  width: 150px;
  border: none;
  padding: 10px;
  border-radius: 5px;
  font-weight: 500;
}

.sidebarHr {
  margin: 20px 0;
}

.sidebarChannelList {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.sidebarInfoContainer {
  padding-top: 5px;
  display: flex;
  height: 2px;
}

a:link,
a:visited {
  color: #333;

  transition: all 0.3s ease-in;
}
a:link,
a:visited a:hover,
a:active {
  color: #8a2be2;
  font-weight: 400;
  transition: all 0.3s ease-in;
}
/* Ensure the hover and active styles are applied */
.app a:hover,
.app a:active {
  color: #8a2be2 !important;
  font-weight: 400 !important;
}

.tooltip {
  position: relative;
  display: inline-block;
  padding-left: 5px;
  transform: translate(-11px, -9px);
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 160px;
  top: 100%;
  left: 50%;
  margin-left: -80px;
  font-size: 12px;
  font-weight: 400;
  background-color: #fff;
  color: #333;
  box-shadow: 0 2.4rem 4.8rem rgba(0, 0, 0, 0.075);
  border-radius: 6px;
  padding: 5px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;

  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
@media (max-width: 280px) {
  .tooltip .tooltiptext {
    width: 122px;
    left: 14%;
    top: 113%;
    margin-left: -87px;
  }
  .tooltip {
    transform: translate(-0.5rem);
  }
}
