.centerContent {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  background-color: #f0f2f5;
  width: 85%;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* width: 100%; */
}
.errorText {
  color: #b94a48;
  text-align: left;
}
.centerContainer {
  display: flex;
  justify-content: center;
}
.centeredErrorText {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.centered-flex {
  display: flex;
  justify-content: center;
}
.custom-box {
  margin: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 80%;
}
.flex-column-start {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align the cards to the left */
}
.info-text {
  margin: 10px 0;
  color: white;
  font-size: 14px;
}
