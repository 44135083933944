.messenger {
  /* height: 100%; */
  /* height: calc(100vh - 70px); */
  height: calc(100vh - 50px);
  display: flex;
}
.messengerMobile {
  /* height: 100%; */
  height: calc(100vh - 150px);
  display: flex;
  padding-top: 150px;
  /* width: 1260px; */
}

.chatMenu {
  flex: 3.5;
  height: calc(100vh - 70px);
  /* height: 100%; */
  overflow-y: scroll;
}

.chatMenuInput {
  width: 90%;
  padding: 10px 0;
  border: none;
  border-bottom: 1px solid gray;
}

.chatBox {
  flex: 5.5;
}
.chatBoxMobile {
  flex: 5.5;
  height: 100%;
}

.chatBoxWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.chatBoxTop {
  /* height: 100%; */
  height: calc(100vh - 70px);
  overflow-y: scroll;
  padding-right: 10px;
  overflow-x: hidden;
}
.typing-indicator {
  height: 20px; /* Adjust the height as needed */
  display: flex;
  align-items: center;
  padding: 5px;
  box-sizing: border-box;
}
.chatBoxBottom {
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 5px;
}

.chatMessageInput {
  width: 80%;
  height: 90px;
  padding: 10px;
}

.chatMessage_wrap {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background: var(--bg-forth);
  border-radius: 50px;
  padding-right: 10px;
}
.chatMessage_wrapMobile {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background: var(--bg-forth);
  border-radius: 50px;
  padding-right: 10px;
}
.chatMessage_wrapMobile input {
  outline: none;
  border: none;
  flex: 1;
  height: 80px;
  font-size: 30px;
  padding: 0 10px;
  color: var(--color-primary);
  background: transparent;
}
.viewConversationsMobile {
  margin-top: 10px;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  color: #333;

  transition: all 0.3s;
}

.chatSubmitButton {
  width: 70px;
  height: 40px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #8a2be2;
  color: white;
}
.voiceMemoSendButton {
  width: 70px;
  height: 40px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #8a2be2;
  color: white;
  margin: auto;
}
.voiceMemoSendButtonSmall {
  width: 28px;
  height: 40px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #8a2be2;
  color: white;
  margin: auto;
}
.voiceMemoCancelButton {
  width: 70px;
  height: 40px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: grey;
  color: white;
  margin: auto;
}

.voiceMemoCancelButtonSmall {
  width: 30px;
  height: 40px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: grey;
  color: white;
  margin: auto;
}

.mic_circle_icon {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  cursor: pointer;
  margin: auto;
}

.chatOnline {
  flex: 3;
  /* height: calc(100vh - 70px); */
  height: 98%;
}

.chatMenuWrapper,
.chatBoxWrapper,
.chatOnlineWrapper {
  padding: 10px;
  height: 98%;
  /* height: calc(100vh - 70px); */
}

.noConversationText {
  position: absolute;
  top: 10%;
  font-size: 50px;
  color: rgb(224, 220, 220);
  cursor: default;
}
.noConversationTextMobile {
  position: absolute;
  top: 10%;
  font-size: 100px;
  color: rgb(224, 220, 220);
  cursor: default;
}

.messengerError_error {
  width: 90%;
  font-weight: 600;
  /* padding-bottom: 5px; */
  padding-left: 16px;
}

.viewMessages {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  color: #333;

  transition: all 0.3s;
}
.viewMessagesMobile {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  color: #333;

  transition: all 0.3s;
}
.openConversation {
  display: flex;
  flex-direction: column;
}
.flex-inline {
  display: contents;
}
.messengerAdRotatorContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 15px;
}

.audio {
  max-height: 100%;
  max-width: 100%;
  margin: auto;
  object-fit: contain;
}
.audioClear {
  display: flex;
  align-items: center;
  column-gap: 5px;
}
.noClicks {
  pointer-events: none;
}
/*----share error----*/

/* ------------- responsive ------------- */
@media (max-width: 1295px) {
  .messenger {
    /* width: 1260px; */
  }
}

/* .react-emoji {
  font-size: 24px;
} */
@media (max-width: 820px) {
  .chatBoxTop {
    margin-top: 20px;
  }
}
@media (max-width: 805px) {
  .topbarContainerMobileChat {
    width: 100%;
  }
  .noConversationTextMobile {
    font-size: 36px;
  }
  .chatMenu {
    padding-top: 40px;
  }
}
@media (max-width: 800px) {
  .chatMessage_wrapMobile {
    height: 70%;
  }
  .chatMessage_wrapMobile input {
    font-size: 22px;
  }
  .chatBoxTop {
    margin-top: 55px;
  }
}
@media (max-width: 768px) {
  .chatOnline {
    margin-top: 36px;
  }
  .chatMenu {
    margin-top: 43px;
  }
  .chatBoxTop {
    margin-top: 99px;
  }
}
@media (max-width: 712px) {
  .chatMessage_wrapMobile {
    height: 70%;
  }
  .chatMessage_wrapMobile input {
    font-size: 25px;
  }
}
@media (max-width: 640px) {
  .chatMessage_wrapMobile {
    height: 53%;
  }
  .chatMessage_wrapMobile input {
    font-size: 19px;
  }
}
@media (max-width: 600px) {
  .chatBoxMobile {
    width: 55%;
    margin-top: 95px;
  }
  .chatMenu {
    width: 75%;
    margin-top: 55px;

    /* margin-top: 50px; */
  }
  .chatOnline {
    margin-top: 55px;
  }
  .noConversationTextMobile {
    font-size: 28px;
  }
  .chatMessage_wrapMobile {
    width: 102%;
    height: 50%;
  }
  .chatMessage_wrapMobile input {
    font-size: 12px;
  }
  .chatBoxTop {
    margin-top: 30px;
  }
}
@media (max-width: 540px) {
  .audioClear {
    width: 262px;
  }
  .chatBoxTop {
    margin-top: 15px;
  }
  .chatMenu {
    margin-top: 40px;
  }
  .chatOnline {
    margin-top: 50px;
  }
}
@media (max-width: 530px) {
  .chatMessage_wrapMobile input {
    height: 49px;
    font-size: 13px;
  }
}
@media (max-width: 480px) {
  .viewConversationsMobile {
    font-size: 11px;
  }
  .audioClear {
    width: 220px;
  }
  .chatMenu {
    margin-top: 50px;
  }
}
@media (max-width: 414px) {
  .chatBoxMobile {
    width: 52%;
    margin-top: 95px;
  }
  .chatMenu {
    width: 75%;
    margin-top: 55px;

    /* margin-top: 50px; */
  }
  .chatOnline {
    margin-top: 55px;
  }
  .noConversationTextMobile {
    font-size: 28px;
  }
  .chatMessage_wrapMobile {
    width: 117%;
    height: 50%;
  }
  .chatMessage_wrapMobile input {
    font-size: 12px;
  }
  .audioClear {
    width: 220px;
  }
}
@media (max-width: 412px) {
  .chatMessage_wrapMobile {
    width: 117%;
    height: 77%;
  }
  .chatMenu {
    margin-top: 10px;
  }
  .chatOnline {
    margin-top: 15px;
  }
  .chatBoxTop {
    margin-top: 0px;
  }
  .viewMessagesMobile {
    font-size: 15px;
  }
}

@media (max-width: 395px) {
  .chatMenu {
    width: 75%;
    margin-top: 75px;
    /* margin-top: 50px; */
  }
  .chatBox {
    margin-top: 50px;
  }
  .chatOnline {
    /* margin-top: 50px; */
  }
  .noConversationTextMobile {
    font-size: 28px;
  }
  .chatMessage_wrapMobile {
    width: 96%;
    height: 50%;
  }
  .chatMessage_wrapMobile input {
    font-size: 12px;
  }
}

@media (max-width: 393px) {
  .chatBoxTop {
    margin-top: 0px;
  }
  .chatMenu {
    margin-top: 13px;
  }
  .chatMessage_wrapMobile {
    width: 117%;
    height: 77%;
  }
  .chatMessage_wrapMobile input {
    font-size: 10px;
  }
  .viewMessagesMobile {
    font-size: 14px;
  }
}

@media (max-width: 390px) {
  .viewConversationsMobile {
    font-size: 11px;
  }
  .chatMessage_wrapMobile input {
    font-size: 10px;
  }
  .chatMenu {
    margin-top: 15px;
  }
}
@media (max-width: 384px) {
  .chatMenu {
    margin-top: 10px;
  }
  .audioClear {
    width: 230px;
  }
  .chatMessage_wrapMobile {
    width: 117%;
    height: 50%;
  }
  .chatOnline {
    margin-top: 12px;
  }
}

@media (max-width: 375px) {
  .chatMenu {
    margin-top: 30px;
  }
  .chatBoxMobile {
    width: 47%;
    margin-top: 85px;
  }
  .chatOnline {
    margin-top: 40px;
  }
  .chatMessage_wrapMobile {
    width: 139%;
    height: 50%;
  }
  .chatSubmitButton {
    display: none;
  }
  .chatMessage_wrapMobile input {
    font-size: 12px;
  }

  .viewMessagesMobile {
    font-size: 13px;
  }
  .audioClear {
    width: 220px;
  }
}

@media (max-width: 360px) {
  .chatMenu {
    margin-top: 5px;
  }

  .viewConversationsMobile {
    font-size: 10px;
  }
  .chatOnline {
    margin-top: 10px;
  }
  .chatMessage_wrapMobile {
    width: 144%;
    height: 50%;
  }
  .chatMessage_wrapMobile input {
    font-size: 9px;
  }
}
@media (max-width: 353px) {
  .chatBoxMobile {
    width: 53%;
  }
}
@media (max-width: 320px) {
  .viewConversationsMobile {
    font-size: 10px;
  }
  .audioClear {
    width: 215px;
  }
  .chatMessage_wrapMobile {
    width: 161%;
    height: 50%;
  }
  .chatBoxTop {
    margin-top: -3px;
  }
  .viewMessagesMobile {
    font-size: 15px;
  }
}

@media (max-width: 280px) {
  .viewConversationsMobile {
    font-size: 9px;
    padding-left: 2px;
  }
  .chatMenu {
    width: 75%;
    margin-top: 50px;
  }
  .chatOnline {
    margin-top: 60px;
  }
  .chatMessage_wrapMobile {
    width: 117%;
    height: 50%;
  }
  .chatMessage_wrapMobile input {
    font-size: 8px;
  }
  .chatSubmitButton {
    display: none;
  }
  .chatMenuWrapper,
  .chatBoxWrapper,
  .chatOnlineWrapper {
    padding: 0px;
    width: 100%;
  }
  .chatBoxTop {
    width: 80%;
    margin-top: 10px;
  }
  .chatBoxMobile {
    width: 93%;
    margin-top: 100px;
  }
  .viewMessagesMobile {
    font-size: 9px;
  }
  .audioClear {
    width: 203px;
  }
}
@media (max-width: 240px) {
  .viewConversationsMobile {
    font-size: 7px;
    padding-left: 1px;
  }
  .chatMenu {
    width: 75%;
    /* margin-top: 50px; */
  }
  .chatOnline {
    margin-top: 15px;
  }
  .chatMessage_wrapMobile {
    width: 133%;
    height: 50%;
  }
  .chatMessage_wrapMobile input {
    font-size: 7px;
  }
  .chatSubmitButton {
    display: none;
  }
  .chatMenuWrapper,
  .chatBoxWrapper,
  .chatOnlineWrapper {
    padding: 0px;
    width: 100%;
  }
  .chatBoxTop {
    width: 80%;
  }
  .chatBoxMobile {
    width: 93%;
    margin-top: 100px;
  }
  .viewMessagesMobile {
    font-size: 14px;
  }
}
