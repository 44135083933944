.createAdFeed {
  flex: 5.5;
}

.createAdFeedWrapper {
  padding: 20px;
}

.createAdShare {
  width: 100%;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
}
.createAdShareDarkTheme {
  width: 100%;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  box-shadow: 0px 0px 16px -8px rgba(255, 255, 255, 0.68);
}

.createAdShareWrapper {
  padding: 10px;
}

.createAdInput {
  height: 50px;
  border-radius: 10px;
  border: 1px solid gray;
  font-size: 18px;
  padding-left: 20px;
  margin-bottom: 20px;
}

.payButton,
.payButton:link,
.payButton:visited {
  height: 50px;
  border-radius: 10px;
  border: none;
  background-color: #8a2be2;
  color: white;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  width: 230px;

  transition: all 0.3s;
}

.payButton:hover,
.payButton:active {
  background-color: #639;
}

.createAdBox {
  height: 300px;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
}

.createAdDesc {
  font-size: 24px;
  color: #333;
  width: 200px;
  margin: 0 auto;
}

.shareProfileImgCreateAd {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
  pointer-events: none;
}
.stripeImg {
  width: 200px;
  height: 200px;
  border-radius: 10%;
  object-fit: cover;
  margin-right: 10px;
  pointer-events: none;
}

@media (max-width: 628px) {
  .createAdDesc {
    font-size: 12px;
  }
  .createAdInput {
    font-size: 11px;
  }
  .payButton {
    width: 160px;
  }
  .stripeImg {
    width: 95%;
    height: auto;
  }
}
@media (max-width: 554px) {
  .createAdInput {
    width: 60px;
    font-size: 11px;
  }
  .payButton {
    width: 87px;
    font-size: 13px;
  }
  .createAdDesc {
    font-size: 9px;
    width: 100px;
  }
  .createAdBox {
    padding: 13px;
  }
  .spaceForPage {
    padding-left: 2px;
  }
}
