.createGalaxySubmitButton {
  display: block;
  margin: 0 auto;
  background-color: #639;
  color: white;
  padding: 5px 0;
  border-radius: 10px;
  border: none;
  font-size: 20px;
  height: 40px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease-in;
}
.formContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.buttonContainer {
  display: flex;
  justify-content: center;
}
.fullWidth {
  width: 100%;
  box-sizing: border-box;
  resize: none;
}
