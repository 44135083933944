.chat-container {
  display: flex;
  flex-direction: column;
  height: 100%; /* Changed from 100vh to 100% */
  overflow: auto; /* This will add a scrollbar if the content exceeds the max-height */
  /* max-height: 50vh; */
}

.chat-messages {
  height: 80%; /* Adjust as needed */
  flex: 1;
  overflow-y: auto;
  position: relative;
}
.centered-column-galaxyChatBox {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers children horizontally */
  justify-content: center; /* Centers children vertically if needed */
  width: 100%; /* Takes full width of the parent */
}

.chat-messages div {
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
}

.chat-input {
  margin-top: auto;
  display: flex;
  padding: 10px;
}
.chatBoxBottomContainer {
  margin-bottom: 5px;
  margin-left: 5px;
  margin-right: 5px;
}
.chatBoxBottom {
  margin-top: auto;
}
.input-field {
  flex: 1;
  margin-right: 10px;
}
.message-own {
  margin-right: 5px; /* Adjust as needed */
}

.message-other {
  margin-left: 5px; /* Adjust as needed */
}
.send-button {
  /* Add styles for the send button if needed */
}
