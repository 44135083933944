.comment {
  display: flex;
  gap: 5px;
  font-size: 13px;
  margin-bottom: 5px;
}
.comment.dark-theme {
  background-color: #333 !important;
  color: white !important;
}

.commentProfileImage {
  width: 33px;
  height: 33px;
  border-radius: 50%;
  object-fit: cover;
}
.commentProfileImageMobile {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
}

.commentImage {
  margin-top: 5px;
  width: 200px;
  height: 150px;
  border-radius: 5px;
  object-fit: cover;
  /* pointer-events: none; */
}

.commentWrap {
  background: var(--bg-forth);
  padding: 5px 15px;
  border-radius: 30px;
}

.commentActions {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 12px;
  font-weight: 600;
  color: var(--color-secondary);
  margin-left: 10px;
}

.commentTextMobile {
  font-size: 29px;
  max-width: 250px;
  white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
  white-space: -webkit-pre-wrap; /* Chrome & Safari */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  white-space: pre-wrap; /* CSS3 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  word-break: keep-all;
  white-space: normal;
}

.commentText {
  max-width: 128px;
  white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
  white-space: -webkit-pre-wrap; /* Chrome & Safari */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  white-space: pre-wrap; /* CSS3 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  word-break: keep-all;
  white-space: normal;
}
.commentText.dark-theme,
.commentTextMobile.dark-theme {
  background-color: black !important;
  color: white !important;
}
/* Ensure links are styled and clickable */
.commentText a,
.commentTextMobile a {
  pointer-events: auto; /* Ensure pointer events are enabled */
  color: blue; /* Ensure the link is visible */
  text-decoration: underline; /* Make it look like a link */
}

@media (max-width: 1308px) {
  .commentText {
    /* max-width: 230px; */
    max-width: 141px;
  }
}

@media (max-width: 912px) {
  .commentImage {
    width: 400px;
    height: 400px;
  }
}

@media (max-width: 800px) {
  .commentProfileImageMobile {
    width: 70px;
    height: 70px;
  }
  .commentTextMobile {
    font-size: 25px;
  }
}
@media (max-width: 768px) {
  .commentImage {
    width: 290px;
    height: 290px;
  }
}
@media (max-width: 712px) {
  .commentProfileImageMobile {
    width: 60px;
    height: 60px;
  }
  .commentTextMobile {
    font-size: 15px;
  }
}
@media (max-width: 600px) {
  .commentProfileImageMobile {
    width: 60px;
    height: 60px;
  }
  .likeIconMobile {
    width: 30px;
    height: 30px;
  }
}
@media (max-width: 578px) {
  .commentNameMobile {
    font-size: 22px;
  }
  .commentTextMobile {
    font-size: 22px;
    max-width: 176px;
  }
}

@media (max-width: 540px) {
  .commentImage {
    width: 254px;
    height: 254px;
  }

  .commentTextMobile {
    max-width: 161px;
  }
}

@media (max-width: 520px) {
  .commentProfileImageMobile {
    width: 50px;
    height: 50px;
  }
  .commentTextMobile {
    font-size: 20px;
  }
}
@media (max-width: 440px) {
  .commentProfileImageMobile {
    width: 40px;
    height: 40px;
  }
  .commentTextMobile {
    font-size: 15px;
    max-width: 150px;
    white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
    white-space: -webkit-pre-wrap; /* Chrome & Safari */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    white-space: pre-wrap; /* CSS3 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
    word-break: keep-all;
    white-space: normal;
  }
}

@media (max-width: 414px) {
  .commentImage {
    width: 199px;
    height: 199px;
  }
  .commentTextMobile {
    max-width: 123px;
  }
}

@media (max-width: 393px) {
  .commentTextMobile {
    max-width: 111px;
  }
}

@media (max-width: 390px) {
  .commentTextMobile {
    max-width: 110px;
  }
}
@media (max-width: 384px) {
  .likeIconMobile {
    width: 25px;
    height: 25px;
  }
}
@media (max-width: 375px) {
  .commentImage {
    width: 182px;
    height: 182px;
  }
  .commentTextMobile {
    max-width: 100px;
  }
}

@media (max-width: 360px) {
  .commentImage {
    width: 170px;
    height: 170px;
  }
  .commentTextMobile {
    max-width: 91px;
  }
}
@media (max-width: 320px) {
  .commentTextMobile {
    font-size: 13px;
  }
  .viewCommentsMobile {
    font-size: 13px;
  }
}
@media (max-width: 280px) {
  .commentProfileImageMobile {
    width: 30px;
    height: 30px;
  }
  .commentTextMobile {
    font-size: 12px;
    max-width: 57px;
  }
  .commentImage {
    width: 113px;
    height: 113px;
  }
  .comment {
    width: 216px;
  }
}
