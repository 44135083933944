.homeContainer {
  display: flex;
  /* width: 100%; */
  /* position: absolute;
  left: 50%;
  transform: translateX(-50%); */
}

/* ------------- responsive ------------- */
@media (max-width: 1295px) {
  /* .homeContainer {
    width: 1260px;
  } */
}

@media (max-width: 979px) {
  .rightbar {
    display: none;
  }
  .sidebar {
    display: none;
  }
}
@media (max-width: 805px) {
  .sidebar {
    display: none;
  }
  /* .rightbar {
    display: none;
  } */
}
