.memeContainer {
  display: flex;
  width: 100%;
}

.centerMemeContainer {
  margin: 0 auto;
  overflow: hidden;
  overflow-y: auto;
  max-height: 600px;
  width: 93%;
}

.memeContainerWrapper {
  margin: 0 auto;
}

.olollist {
  margin: 0;
  padding: 0;
  list-style-type: none;
  height: 100vh;
  overflow: auto;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}
.olollist::-webkit-scrollbar {
  display: none;
}
.memeTitle {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 10px;
}

.imgVidContainer {
  width: 100%;
}

.feedMeme {
  flex: 5.5;
}

.feedWrapper {
  padding: 20px;
}

.memeContainerElement {
  width: 100%;
}

.viewMemes {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  color: #333;

  transition: all 0.3s;
}

.viewMemes:hover {
  color: #8a2be2;
}

.memeButtonRank,
.memeButtonRank:link,
.memeButtonRank:visited {
  height: 50px;
  border-radius: 10px;
  border: none;
  background-color: #8a2be2;
  color: white;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  width: 150px;

  transition: all 0.3s;
}

.memeButtonRank:hover,
.memeButtonRank:active {
  background-color: #639;
}
/* ------------- responsive ------------- */
@media (max-width: 1295px) {
  .memeContainer {
    /* width: 1260px; */
  }
}

@media (max-width: 805px) {
  /* .rightbar {
    display: none;
  } */
}

@media (max-width: 584px) {
  .feedMeme {
    width: 81%;
  }
}
@media (max-width: 320px) {
  .memeByName {
    font-size: 12px;
  }
  .memeViewsMobile {
    font-size: 12px;
  }
  .postDateMobile {
    font-size: 12px;
  }
  .memeLikeCounter {
    font-size: 12px;
  }
}
