.errorMessageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  padding: 20px; /* Add padding to ensure content is not too close to the edges */
  box-sizing: border-box; /* Ensure padding is included in the element's total width and height */
}

.errorContainer {
  background-color: #fff; /* Optional: Add a background color */
  padding: 20px;
  border-radius: 8px; /* Optional: Add rounded corners */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle shadow */
  max-width: 90%; /* Ensure the container doesn't exceed the viewport width */
  width: 100%;
  box-sizing: border-box; /* Ensure padding is included in the element's total width */
}
.errorContainerDarkTheme {
  background-color: #fff; /* Optional: Add a background color */
  padding: 20px;
  border-radius: 8px; /* Optional: Add rounded corners */
  box-shadow: 0 2px 10px #ffff; /* Optional: Add a subtle shadow */
  max-width: 90%; /* Ensure the container doesn't exceed the viewport width */
  width: 100%;
  box-sizing: border-box; /* Ensure padding is included in the element's total width */
}

.error-message-buttons {
  display: flex;
  gap: 10px; /* Adjust the gap as needed */
  margin-top: 20px; /* Add some space above the buttons */
  justify-content: center; /* Center the buttons horizontally */
}

.postButtonRank {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}
