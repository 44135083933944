.messageText {
  padding: 10px;
  border-radius: 20px;
  background-color: #8a2be2;
  color: white;
  max-width: 300px;
}
.messageTextMobile {
  padding: 10px;
  border-radius: 20px;
  background-color: #8a2be2;
  color: white;
  max-width: 300px;
  font-size: 50px;
  /* max-height: 600px; */
}

.postText {
  font-size: 15px;
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
  white-space: -webkit-pre-wrap; /* Chrome & Safari */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  white-space: pre-wrap; /* CSS3 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  word-break: keep-all;
  white-space: normal;
}
.postTextMobile {
  font-size: 29px;
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
  white-space: -webkit-pre-wrap; /* Chrome & Safari */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  white-space: pre-wrap; /* CSS3 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  word-break: keep-all;
  white-space: normal;
}

@media (max-width: 768px) {
  .postTextMobile {
    font-size: 20px;
  }
  .postPlayerIdMobile {
    font-size: 20px;
  }
  .postViewsMobile {
    font-size: 20px;
  }
}
@media (max-width: 712px) {
  .postTextMobile {
    font-size: 20px;
  }
}
@media (max-width: 600px) {
  .postTextMobile {
    font-size: 20px;
  }
}
@media (max-width: 578px) {
  .postTextMobile {
    font-size: 15px;
    white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
    white-space: -webkit-pre-wrap; /* Chrome & Safari */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    white-space: pre-wrap; /* CSS3 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
    word-break: keep-all;
    white-space: normal;
  }
}
@media (max-width: 320px) {
  .postTextMobile {
    font-size: 14px;
  }
}
