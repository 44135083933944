.subscriberWrapper {
  position: relative;
}
.galaxySubscriberImgs {
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex-wrap: wrap; */
}
.centerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.linkWrapper {
  display: inline-block;
}
.playerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
  flex-direction: column;
}
.xButtonGalaxyPlayer {
  width: 10px; /* Size of the button */
  height: 10px; /* Size of the button */
  border-radius: 50%; /* Rounded corners */
  font-size: 14px; /* Adjust based on the size of the button */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none; /* Remove border */
  background-color: rgb(245, 241, 241);
  color: #8a2be2; /* Color of the 'X' */
  transform: translateY(-3px);
}
.xButtonGalaxyPlayerDark {
  width: 10px; /* Size of the button */
  height: 10px; /* Size of the button */
  border-radius: 50%; /* Rounded corners */
  font-size: 14px; /* Adjust based on the size of the button */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none; /* Remove border */
  background-color: black;
  color: white; /* Color of the 'X' */
  transform: translateY(-3px);
}
