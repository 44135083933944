/*-----create comment-----*/
.comments_wrap {
  padding: 10px 15px;
}
.create_comment {
  display: flex;
  align-items: center;
  gap: 5px;
  width: 262px;
  margin-bottom: 10px;
}
.create_commentMobile {
  display: flex;
  align-items: center;
  gap: 5px;
  width: 700px;
  height: 80px;
  margin-bottom: 10px;
}

.commentName {
  font-weight: 500;
  font-size: 13px;
  color: #333;
}
.commentNameMobile {
  font-weight: 500;
  color: #333;
  font-size: 25px;
}

.create_comment img {
  width: 33px;
  height: 33px;
  border-radius: 50%;
  object-fit: cover;
}
.create_commentMobile img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
}
.comment_input_wrap {
  position: relative;
  width: 81%;
  height: 100%;
  display: flex;
  align-items: center;
  background: var(--bg-forth);
  border-radius: 50px;
  padding-right: 10px;
  column-gap: 2px;
}
.comment_input_wrap input {
  outline: none;
  border: none;
  flex: 1;
  height: 35px;
  padding: 0 10px;
  color: var(--color-primary);
  background: transparent;
}
.comment_input_wrapMobile {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background: var(--bg-forth);
  border-radius: 50px;
  padding-right: 10px;
}
.comment_input_wrapMobile input {
  outline: none;
  border: none;
  flex: 1;
  height: 80px;
  font-size: 30px;
  padding: 0 10px;
  color: var(--color-primary);
  background: transparent;
}

.commentInputText {
  font-size: 13px;
}
.comment_circle_icon {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  cursor: pointer;
}
.comment_circle_iconMobile {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  cursor: pointer;
}
.comment_error {
  padding: 0 !important;
  opacity: 1 !important;
}
.comment_img_preview {
  position: relative;
  margin-top: 5px;
  margin-left: 40px;
  width: 160px;
}
.comment_img_preview img {
  width: 150px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
}
.comment_img_previewMobile {
  position: relative;
  margin-top: 5px;
  margin-left: 40px;
  width: 320px;
}
.comment_img_previewMobile img {
  width: 450px;
  height: 300px;
  object-fit: cover;
  border-radius: 5px;
}
.comment_img_preview .small_white_circle {
  top: -6px;
  right: -4px;
  transform: scale(0.8);
}
.comment_img_previewMobile .small_white_circleMobile {
  top: -6px;
  right: -4px;
  transform: scale(0.8);
}
/*-----create comment-----*/

.comment_emoji_picker {
  position: absolute;
  right: 0;
  bottom: 2.5rem;
}

.comment_error {
  padding: 0 !important;
  opacity: 1 !important;
}

/*----post error----*/
.postError {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: var(--bg-primary);
  opacity: 0.9;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  z-index: 999;
  color: #b94a48;
  animation: anim 0.3s;
  border-radius: 5px;
  padding: 3rem;
}
.postError_error {
  width: 75%;
  font-weight: 600;
}
/*----post error----*/
.__react_modal_image__medium_img {
  max-width: 90vw; /* limit to 90% of the viewport width */
  max-height: 90vh; /* limit to 90% of the viewport height */
  object-fit: contain; /* ensure the aspect ratio of the image is preserved */
}
.blue_btn {
  border: none;
  outline: none;
  background: #8a2be2;
  padding: 8.5px 12px;
  border-radius: 7px;
  font-family: inherit;
  font-weight: 600;
  font-size: 14px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  cursor: pointer;
}

.small_white_circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid var(--bg-third);
  background: var(--bg-primary);
  display: grid;
  place-items: center;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  z-index: 99;
}

.hue {
  animation: hue 1s infinite;
}

@keyframes hue {
  0%,
  100% {
    -webkit-filter: hue-rotate(0deg);
  }
  50% {
    -webkit-filter: hue-rotate(180deg);
  }
}

.microphone_icon {
  height: 24px;
  width: 24px;
  background-image: url('https://res.cloudinary.com/joegalaxy/image/upload/v1698531722/icons9_nlvpej.png');
  background-position: 0px -100px;
  background-size: auto;
  background-repeat: no-repeat;
  display: inline-block;
  filter: invert(40%);
}
.microphone_iconPlaying {
  height: 24px;
  width: 24px;
  background-image: url('https://res.cloudinary.com/joegalaxy/image/upload/v1698531722/icons9_nlvpej.png');
  background-position: 0px -100px;
  background-size: auto;
  background-repeat: no-repeat;
  display: inline-block;
}
.microphone_iconMobile {
  height: 24px;
  width: 24px;
  background-image: url('https://res.cloudinary.com/joegalaxy/image/upload/v1698531722/icons9_nlvpej.png');
  background-position: 0px -100px;
  background-size: auto;
  background-repeat: no-repeat;
  display: inline-block;
  filter: invert(40%);
}
.microphone_iconMobilePlaying {
  height: 24px;
  width: 24px;
  background-image: url('https://res.cloudinary.com/joegalaxy/image/upload/v1698531722/icons9_nlvpej.png');
  background-position: 0px -100px;
  background-size: auto;
  background-repeat: no-repeat;
  display: inline-block;
}

.addPhoto_icon {
  background-image: url('https://res.cloudinary.com/joegalaxy/image/upload/v1698451384/icons7_x9llvh.png');
  background-position: -25px -292px;
  background-size: auto;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  display: inline-block;
}

.exit_icon {
  background-image: url('https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1658712082/icons6_dpdlxd.png');
  background-position: -126px -71px;
  background-size: auto;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  display: inline-block;
  filter: invert(40%);
}

.comment_icon {
  background-image: url('https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1658712594/icons5_jblzvf.png');
  background-position: 0 -205px;
  background-size: auto;
  width: 18px;
  height: 18px;
  background-repeat: no-repeat;
  display: inline-block;
  filter: invert(40%);
}
.share_icon {
  background-image: url('https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1658712594/icons5_jblzvf.png');
  background-position: 0 -262px;
  background-size: auto;
  width: 18px;
  height: 18px;
  background-repeat: no-repeat;
  display: inline-block;
  filter: invert(40%);
}
.share_iconMobile {
  background-image: url('https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1658712594/icons5_jblzvf.png');
  background-position: 0 -262px;
  background-size: auto;
  width: 18px;
  height: 18px;
  background-repeat: no-repeat;
  display: inline-block;
  filter: invert(40%);
}
.emoji_icon {
  background-image: url('https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1658712594/icons5_jblzvf.png');
  background-position: 0px -434px;
  background-size: auto;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  display: inline-block;
  filter: invert(40%);
}
.camera_icon {
  background-image: url('https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1658712594/icons5_jblzvf.png');
  background-position: 0px -366px;
  background-size: auto;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  display: inline-block;
  filter: invert(40%);
}
.gif_icon {
  background-image: url('https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1658712594/icons5_jblzvf.png');
  background-position: 0px -451px;
  background-size: auto;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  display: inline-block;
  filter: invert(40%);
}
.sticker_icon {
  background-image: url('https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1658712594/icons5_jblzvf.png');
  background-position: 0px -468px;
  background-size: auto;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  display: inline-block;
  filter: invert(40%);
}

:root {
  --bg-primary: #fff;
  --bg-secondary: #f0f2f5;
  --bg-third: #e4e6eb;
  --bg-forth: #f0f2f5;
  --bg-jgcolora: #8a2be2;
  --bg-jgcolorb: #450b7a;
  --color-primary: #050505;
  --color-secondary: #65676b;
  --divider: #ced0d4;
  --dark-bg-primary: #18191a;
  --dark-bg-secondary: #242526;
  --dark-bg-third: #3a3b3c;
  --dark-color-primary: #242526;
  --dark-color-secondary: #b0b3b8;
  --blue-color: #1876f2;
  --green-color: #42b72a;
  --light-blue-color: #e7f3ff;
  --border-color: #ccced2;
  --shadow-1: rgba(0, 0, 0, 0.2);
  --shadow-2: rgba(0, 0, 0, 0.1);
  --shadow-3: rgba(0, 0, 0, 0.3);
  --shadow-inset: rgba(255, 255, 255, 0.5);
  --dark-mode-secondary-text: rgba(168, 168, 168, 1);
}

/* .createCommentProfileImgMobile {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  pointer-events: none;
} */
@media (max-width: 1261px) {
  .create_comment {
    width: 260px;
  }
}
@media (max-width: 1174px) {
  .commentInputText {
    font-size: 80%;
  }
  .create_comment {
    width: 260px;
  }
}
@media (max-width: 777px) {
  .comment_input_wrapMobile {
    width: 50%;
  }
  .comment_input_wrapMobile input {
    font-size: 20px;
  }

  .channelBottom {
    width: 40%;
  }
  .channelBottomLeft {
    width: 80%;
  }
  .postLikeCounterMobile {
    font-size: 28px;
    word-wrap: break-word;
  }
}

@media (max-width: 800px) {
  .create_commentMobile img {
    width: 70px;
    height: 70px;
  }
  .comment_input_wrapMobile {
    height: 75%;
  }
  .comment_input_wrapMobile input {
    font-size: 25px;
  }
}
@media (max-width: 768px) {
  .create_commentMobile img {
    width: 70px;
    height: 70px;
  }
  .postLikeCounterMobile {
    font-size: 15px;
  }
  .postLikeProfileImgs img {
    width: 40px;
    height: 40px;
  }
  .postRePostCounter {
    font-size: 15px;
  }
}
@media (max-width: 712px) {
  .create_commentMobile img {
    width: 60px;
    height: 60px;
  }
  .comment_input_wrapMobile {
    height: 75%;
  }
  .comment_input_wrapMobile input {
    font-size: 15px;
  }
  .postViewsMobile {
    font-size: 15px;
  }
}
@media (max-width: 640px) {
  .create_commentMobile img {
    width: 50px;
    height: 50px;
  }
  .comment_input_wrapMobile {
    height: 65%;
  }
}
@media (max-width: 600px) {
  .postLikeCounterMobile {
    font-size: 15px;
  }
  .commentTextMobile {
    font-size: 20px;
  }
  .commentNameMobile {
    font-size: 20px;
  }
  .create_commentMobile img {
    width: 60px;
    height: 60px;
  }
  .comment_input_wrapMobile {
    height: 75%;
  }
}

@media (max-width: 578px) {
  .postLikeCounterMobile {
    font-size: 22px;
  }
}
@media (max-width: 520px) {
  .create_commentMobile img {
    width: 50px;
    height: 50px;
  }
  .create_commentMobile {
    width: 600px;
    height: 60px;
  }
  .comment_input_wrapMobile input {
    font-size: 16px;
  }
  .postLikeCounterMobile {
    font-size: 20px;
  }
  .postProfileImgMobile {
    width: 60px;
    height: 60px;
  }
  .postPlayerIdMobile {
    font-size: 20px;
  }
  .commentNameMobile {
    font-size: 20px;
  }
  .postLikeCounterMobile {
    font-size: 15px;
  }
}

@media (max-width: 440px) {
  .create_commentMobile img {
    width: 40px;
    height: 40px;
  }
  .create_commentMobile {
    width: 500px;
    height: 50px;
  }
  .comment_input_wrapMobile input {
    font-size: 13px;
  }
  .postLikeCounterMobile {
    font-size: 20px;
  }
  .postProfileImgMobile {
    width: 50px;
    height: 50px;
  }
  .postPlayerIdMobile {
    font-size: 13px;
  }
  .commentNameMobile {
    font-size: 15px;
  }
  .postLikeCounterMobile {
    font-size: 13px;
  }
}
@media (max-width: 375px) {
  .create_commentMobile {
    width: 486px;
  }
}
@media (max-width: 360px) {
  .comment_input_wrapMobile {
    width: 42%;
  }
  .commentTextMobile {
    font-size: 15px;
  }
}

@media (max-width: 320px) {
  .comment_input_wrapMobile input {
    font-size: 9px;
  }
  .create_commentMobile img {
    width: 35px;
    height: 35px;
  }
  .comment_input_wrapMobile {
    width: 35%;
  }
  .comments_wrap {
    padding: 10px 0px;
  }
  .create_commentMobile {
    width: 496px;
    height: 44px;
  }
  .commentNameMobile {
    font-size: 10px;
  }
  .postLikeCounterMobile {
    font-size: 10px;
  }
  .postProfileImgMobile {
    width: 40px;
    height: 40px;
  }
  .postPlayerIdMobile {
    font-size: 10px;
  }
  .MuiIconButton-root {
    padding: 3px;
  }
}

@media (max-width: 280px) {
  .comment_input_wrapMobile input {
    font-size: 9px;
  }
  .create_commentMobile img {
    width: 35px;
    height: 35px;
  }
  .comment_input_wrapMobile {
    width: 35%;
  }
  .comments_wrap {
    padding: 10px 0px;
  }
  .create_commentMobile {
    width: 496px;
    height: 44px;
  }
  .commentNameMobile {
    font-size: 10px;
  }
  .postLikeCounterMobile {
    font-size: 10px;
  }
  .postProfileImgMobile {
    width: 40px;
    height: 40px;
  }
  .postPlayerIdMobile {
    font-size: 10px;
  }
  .MuiIconButton-root {
    padding: 3px;
  }
}
