/* styles.css */
.centeredHappyBirthday {
  display: block; /* Use block layout for auto margins to work */
  max-width: 960px; /* Example max-width, adjust based on your content */
  margin: 0 auto; /* Centers the container within its parent */
  width: 100%; /* Ensures it takes up the full width of its parent up to max-width */
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 12px;
  font-size: 4em; /* Adjust font size for better readability on mobile */
}
/* styles.css */
@keyframes flash {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.flash {
  animation: flash 1s infinite;
}
