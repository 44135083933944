/* Context Menu */
/*-------Context menu----*/

.contextmenu {
  padding: 0 0.3rem;
  position: absolute;
  top: 100%;
  right: 1;
  width: 50px;
  border-radius: 10px;
  background: var(--bg-primary);
  box-shadow: 2px 2px 2px var(--shadow-1);
  user-select: none;
  padding: 10px;
  z-index: 9999;
  /* transform: translateY(2rem); */
}
.contextmenu_header {
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 10px;
  gap: 10px;
}
.contextmenu_header img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}
.contextmenu_col {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.contextmenu_col span:first-of-type {
  color: var(--color-primary);
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 1px;
}
.contextmenu_col span:last-of-type {
  font-size: 13px;
}
.contextmenu_main {
  padding: 10px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
}
.contextmenu_splitter {
  width: 100%;
  height: 2px;
  background: var(--bg-third);
  margin-top: 5px;
  margin-bottom: 5px;
}
.contextmenu_span1 {
  font-size: 14px !important;
}
.contextmenu_span2 {
  font-size: 12px !important;
  color: var(--color-secondary);
}
.contextmenu_emailAddressTxt {
  max-width: 153px;
  white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
  white-space: -webkit-pre-wrap; /* Chrome & Safari */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  white-space: pre-wrap; /* CSS3 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  word-break: keep-all;
  white-space: normal;
}
.contextmenu_item {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 5px;
  padding: 7px;
  font-weight: 600;
  font-size: 14px;
  border-radius: 10px;
}

.contextmenu_link {
  display: flex;
  align-items: center;
  text-decoration: none; /* Remove underline */
  color: inherit; /* Inherit text color */
}

.contextmenu_link:hover {
  background-color: #f0f0f0; /* Example hover effect */
}

.vertical_layout {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 280px) {
  .contextmenu {
    transform: translateX(-1rem);
    width: 43px;
  }
}
