.error_text {
  color: #b94a48;
}

.resetBox {
  height: 150px;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

/*---------Media queries -----------*/
@media (max-width: 806px) {
  .loginLeft {
    display: none;
  }
}
@media (min-width: 807px) {
  .logoDescMobile {
    display: none;
  }
}
