.buyJGKTokenFeed {
  flex: 5.5;
}

.buyJGKTokenFeedWrapper {
  padding: 20px;
}

.buyJGKTokenShare {
  width: 100%;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
}

.buyJGKTokenshareProfileImg {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
  pointer-events: none;
}
.createAdShareWrapper {
  padding: 10px;
}

.createAdInput {
  height: 50px;
  border-radius: 10px;
  border: 1px solid gray;
  font-size: 18px;
  padding-left: 20px;
  margin-bottom: 20px;
}

.payButtonJGKToken,
.payButtonJGKToken:link,
.payButtonJGKToken:visited {
  height: 50px;
  border-radius: 10px;
  border: none;
  background-color: #8a2be2;
  color: white;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  width: 230px;

  transition: all 0.3s;
}

.payButtonJGKToken:hover,
.payButtonJGKToken:active {
  background-color: #639;
}

.buyJGKTokenBox {
  height: 300px;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 30px auto;
}

.buyJGKTokenDesc {
  font-size: 24px;
  color: #333;
  width: 200px;
  margin: 0 auto;
}

.clickTxt {
  font-size: 18px;
  color: #333;
  width: 144px;
  margin: 0 auto;
  margin-right: 20px;
}
.stripeImgJGK {
  width: 200px;
  height: 200px;
  border-radius: 10%;
  object-fit: cover;
  margin-right: 10px;
  margin-top: -30px;
  pointer-events: none;
}

.buyJGKTokenDesc {
  margin-top: 15px;
}
.buyJGKTokneDesc2 {
  margin-top: 15px;
  font-size: 12px;
}
.shareProfileImg {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
  pointer-events: none;
}
.kloutImg {
  width: 200px;
  height: 200px;
  border-radius: 10%;
  object-fit: cover;
  margin-right: 10px;
  margin-top: 10px;
  pointer-events: none;
}
.qrCodeImg {
  width: 200px;
  height: 250px;
  border-radius: 10%;
  object-fit: cover;
  margin-right: 10px;
  margin-top: -35px;
  pointer-events: none;
}

@media (max-width: 820px) {
  .payButtonJGKToken {
    width: 200px;
  }
}
@media (max-width: 628px) {
  .buyJGKTokenDesc {
    font-size: 12px;
  }
  .createAdInput {
    font-size: 11px;
  }
  .payButtonJGKToken {
    width: 160px;
  }
  .stripeImg {
    width: 95%;
    height: auto;
  }
}
@media (max-width: 554px) {
  .createAdInput {
    width: 60px;
    font-size: 11px;
  }
  .payButtonJGKToken {
    width: 87px;
    font-size: 13px;
  }
  .buyJGKTokenDesc {
    font-size: 12px;
    width: 100px;
  }
}

@media (max-width: 540px) {
  .buyJGKTokenBox {
    height: 450px;
    padding: 1px;
  }
  .kloutImg {
    width: 100px;
    height: 100px;
  }
  .stripeImg {
    width: 100%;
  }
  .buyJGKTokenshareProfileImg {
    width: 35px;
    height: 35px;
  }
  .buyJGKTokenFeedWrapper {
    padding: 10px;
  }
  .buyJGKTokenDesc {
    display: none;
  }
  .qrCodeImg {
    margin-top: 4px;
  }
  .payButtonJGKToken {
    width: 200px;
  }
  .clickTxt {
    font-size: 15px;
    width: 120px;
  }
  .buyJGKTokeDesc {
    margin-top: -101px;
  }
}
@media (max-width: 414px) {
  .buyJGKTokenBox {
    height: 450px;
    padding: 1px;
  }
  .kloutImg {
    width: 100px;
    height: 100px;
  }
  .stripeImg {
    width: 100%;
  }
  .buyJGKTokenshareProfileImg {
    width: 35px;
    height: 35px;
  }
  .buyJGKTokenFeedWrapper {
    padding: 10px;
  }
  .buyJGKTokenDesc {
    display: none;
  }
  .qrCodeImg {
    margin-top: 4px;
  }
  .payButtonJGKToken {
    width: 200px;
  }
  .clickTxt {
    font-size: 15px;
    width: 120px;
  }
  .buyJGKTokeDesc {
    margin-top: -101px;
  }
}
@media (max-width: 375px) {
  .buyJGKTokenBox {
    height: 400px;
    padding: 1px;
  }
  .kloutImg {
    width: 100px;
    height: 100px;
  }
  .stripeImg {
    width: 100%;
  }
  .stripeImgJGK {
    margin-top: -41px;
  }
  .buyJGKTokenshareProfileImg {
    width: 40px;
    height: 40px;
  }
  .buyJGKTokenFeedWrapper {
    padding: 10px;
  }
  .buyJGKTokenDesc {
    display: none;
  }
  .qrCodeImg {
    margin-top: -2px;
  }
  .payButtonJGKToken {
    width: 200px;
  }
  .clickTxt {
    font-size: 15px;
    width: 120px;
  }
  .buyJGKTokeDesc {
    margin-top: -39px;
  }
}
@media (max-width: 280px) {
  .buyJGKTokenshareProfileImg {
    width: 25px;
    height: 25px;
  }
  .buyJGKTokenBox {
    height: 400px;
    padding: 1px;
  }
  .qrCodeImg {
    margin-top: -5px;
  }
  .stripeImg {
    width: 100%;
  }
  .stripeImgJGK {
    margin-top: -41px;
  }
  .clickTxt {
    font-size: 15px;
    width: 120px;
  }
  .buyJGKTokeDesc {
    margin-top: -39px;
  }
}
