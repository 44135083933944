.post {
  width: 97%;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  margin: 30px 5px;
}
.postDarkTheme {
  width: 97%;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 16px -8px rgba(255, 255, 255, 0.68);
  box-shadow: 0px 0px 16px -8px rgba(255, 255, 255, 0.68);
  margin: 30px 5px;
}
.postPOGW {
  width: 100%;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 16px -8px #8a2be2;
  box-shadow: 0px 0px 16px -8px #8a2be2;
  margin: 30px 0;
}

.postText {
  font-size: 15px;
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
  white-space: -webkit-pre-wrap; /* Chrome & Safari */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  white-space: pre-wrap; /* CSS3 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  word-break: break-word;
  white-space: normal;
}
.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.flashing-icon {
  animation: flash 1.5s infinite;
}

@keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.postWrapper {
  padding: 10px;
}

.postTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.postTopLeft {
  display: flex;
  align-items: center;
}

.postProfileImg {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  pointer-events: none;
}

.postPlayerId {
  font-size: 15px;
  font-weight: 500;
  margin: 0 10px;
}
.postViews {
  font-size: 13px;
  font-weight: 400;
  margin: 0 10px;
}
.postViewsMobile {
  font-size: 13px;
  font-weight: 400;
  margin: 0 10px;
  transform: translateY(-0.19rem);
}

.postDate {
  font-size: 13px;
  font-weight: 400;
}
.postViewCounter {
  margin-left: 3px;
}
.postProfileImgMobile {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  pointer-events: none;
}

.postPlayerIdMobile {
  font-size: 40px;
  font-weight: 500;
  margin: 0 10px;
}

.postDateMobile {
  font-size: 30px;
  font-weight: 400;
}

.postCenter {
  margin: 20px 0;
}

.postImg {
  margin-top: 20px;
  width: 100%;
  /* max-height: 500px; */
  object-fit: contain;
  /* pointer-events: none; */
  display: block;
  margin: 0 auto;
}

.postVid {
  margin-top: 20px;
  width: 100%;
  /* max-height: 500px; */
  object-fit: contain;
}

.myDiv {
  pointer-events: none;
}

.postBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.postComment {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.postBottomLeft {
  display: flex;
  align-items: center;
}

.likeIcon {
  width: 24px;
  height: 24px;
  margin-right: 5px;
  cursor: pointer;
}

.postJGKInputSend {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 80px;
  /* transform: translate(10px, -14px); */
}

.postLikeCounter {
  font-size: 12px;
}
.postRePostCounter {
  font-size: 12px;
  padding-left: 5px;
}
.postJgkdCounter {
  font-size: 12px;
  color: #000000;
  padding-left: 6px;
  padding-top: 15px;
}
.likeIconMobile {
  width: 60px;
  height: 60px;
  margin-right: 5px;
  cursor: pointer;
}

.likeIconLikedMobile {
  width: 60px;
  height: 60px;
  margin-right: 5px;
  cursor: pointer;
  filter: grayscale(1);
}

.postLikeCounterMobile {
  font-size: 30px;
}
.postJgkdCounterMobile {
  font-size: 13px;
  padding-left: 6px;
  padding-top: 13px;
}

.postCommentText {
  cursor: pointer;
  border-bottom: 1px dashed gray;
  font-size: 15px;
}

.postDeletePostText {
  cursor: pointer;
  font-size: 15px;
}
.postDeletePostTextMobile {
  cursor: pointer;
  font-size: 30px;
}

.commentBottom {
  display: flex;
  align-items: center;
}

.viewComments {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  color: #333;

  transition: all 0.3s;
}
.viewCommentsMobile {
  margin-top: 10px;
  font-size: 28px;
  font-weight: 500;
  cursor: pointer;
  color: #333;

  transition: all 0.3s;
}

.pogwIcon {
  margin-left: 1px;
  margin-top: 9px;
  color: white;
  background-color: #8a2be2;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pogwIconIconClicked {
  margin-left: 1px;
  margin-top: 9px;
  color: white;
  background-color: grey;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  cursor: pointer;
  display: flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sendJGKToPostPOGWButton {
  margin: auto;
}
.sendJGKToPostAmountLabel {
  color: black;
}
.sendJGKToPostAmountInputs {
  padding: 11px;
  /* border-radius: 10px; */
  margin-left: -2px;
  margin-bottom: 19px;
  border-radius: 50px;
  /* background: var(--bg-forth); */
  outline: none;
  border: none;
}

.sendJGKToPostAmountInputs input {
  width: 100%;
}

.sendJGKPostSubmitButton {
  /* width: 70px;
    height: 40px; */
  /* padding: 1px; */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #8a2be2;
  color: white;
  margin-left: 5px;
}
.sendJGKPostSubmitButtonDisabled {
  /* width: 70px;
    height: 40px; */
  /* padding: 1px; */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #450b7a;
  color: white;
  margin-left: 5px;
}

.sendJGKToPostPOGWWrapper {
  display: flex;
  height: 44px;
  /* padding-bottom: 15px; */
}
.postJGKAmount {
  display: flex;
  position: absolute;
  transform: translate(41px, -9px);
  font-size: 15px;
  font-weight: 500;
}
.postJGKBadge {
  background-color: limegreen;
  width: 33px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  position: absolute;
  transform: translate(115px, -9px);
}

.postNotificationIconBadgeJGK10K {
  width: 13px;
  height: 13px;
  background-color: limegreen;
  border-radius: 50%;
  color: white;
  position: absolute;
  top: -5px;
  right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}
.pogwTitleSpan {
  font-size: 15px;
  font-weight: 500;
  margin: 0 10px;
  background-color: limegreen;
}
.pogwTitleSpanMobile {
  font-size: 13px;
  font-weight: 500;
  margin: 0 7px;
  background-color: limegreen;
}
.viewComments:hover {
  color: #8a2be2;
}

.comments_wrap {
  padding: 10px 15px;
}

.likeIconLiked {
  filter: grayscale(1);
}

.postLikeProfileImgs {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 5px;
}
.postJgkdProfileImgs {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 5px;
  transform: translate(10px, 2px);
}

.postLikeProfileImgs img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  box-shadow: 0 1px 2px var(--shadow-1);
}
.modalContent {
  display: flex;
  flex-direction: column;
  margin-right: 15px;
}
.postLikeProfileImgs img:hover {
  filter: brightness(110%);
}
.sharePostModal {
  display: flex;
  width: 50%;
}
.postShareModalBtn {
  width: 50px;
}
.postShareCloseSend {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modalHeader {
  display: flex;
  justify-content: center;
  color: #8a2be2;
}
.modalHeaders {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-bottom: 5px;
}
.shareDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.shareCountDiv {
  display: flex;
  align-content: center;
  flex-direction: row;
  align-items: center;
  gap: 3px;
}
.repostDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.sendOutlinedIcon {
  cursor: pointer;
  padding-left: 7px;
}
.dialog::backdrop {
  background: rgba(192, 192, 192, 0.3);
}
.dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 0px solid black;
  /* border-radius: 10px; */
}
.close-button {
  border: none;
  display: inline-block;
  padding: 8px 16px;
  vertical-align: middle;
  overflow: hidden;
  text-decoration: none;
  color: inherit;
  background-color: inherit;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
}

.topright {
  position: absolute;
  right: 0;
  top: 0;
}

.sharePostSubmitButton {
  /* width: 70px;
    height: 40px; */
  /* padding: 1px; */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #8a2be2;
  color: white;
  margin-left: 5px;
}
.sharePostSubmitButtonDisabled {
  /* width: 70px;
    height: 40px; */
  /* padding: 1px; */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #450b7a;
  color: white;
  margin-left: 5px;
}
.postRequestPlayersSearchResults {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow: hidden;
  margin-top: 0px;
  overflow-y: auto;
  width: 80%;
  padding: 0 16px;
  max-height: 180px;
  background-color: #ffffff;
  cursor: pointer;
  border-radius: 30px;
  /* margin-top: 173px;
  margin-right: 50px; */
  transform: translateY(0rem);
  transform: translateX(1rem);
}

@media (max-width: 979px) {
  .share {
    width: 100%;
  }

  .postLikeProfileImgs img {
    width: 25px;
    height: 25px;
  }
}

@media (max-width: 820px) {
  .postLikeProfileImgs img {
    width: 25px;
    height: 25px;
  }
}
@media (max-width: 768px) {
  .postPlayerIdMobile {
    font-size: 24px;
  }
  .postLikeProfileImgs img {
    width: 40px;
    height: 40px;
  }
  .postViewsMobile {
    font-size: 20px;
    font-weight: 500;
    margin: 0 10px;
    transform: translateY(-0.19rem);
  }
  .postDateMobile {
    font-size: 20px;
  }
  .pogwTitleSpanMobile {
    font-size: 24px;
  }
  .likeIconMobile {
    width: 35px;
    height: 35px;
  }
  .postLikeCounterMobile {
    font-size: 25px;
  }
  .postJGKAmount {
    transform: translate(87px, -13px);
    font-size: 24px;
  }
  .postJGKBadge {
    transform: translate(180px, -11px);
  }
  .pogwIcon {
    height: 22px;
    width: 22px;
  }
  .postProfileImgMobile {
    width: 70px;
    height: 70px;
  }
}

@media (max-width: 800px) {
  .postLikeProfileImgs img {
    width: 60px;
    height: 60px;
  }
  .likeIconMobile {
    width: 30px;
    height: 30px;
  }
  .postLikeCounterMobile {
    font-size: 20px;
  }
  .postPlayerIdMobile {
    font-size: 25px;
  }
  .postViewsMobile {
    font-size: 20px;
    /* transform: translateY(-0.19rem); */
  }
  .postRePostCounter {
    font-size: 20px;
  }
  .commentTextMobile {
    font-size: 25px;
  }
  .viewCommentsMobile {
    font-size: 20px;
  }
}
@media (max-width: 712px) {
  .viewCommentsMobile {
    font-size: 15px;
  }
}
@media (max-width: 654px) {
  .comments_wrap {
    width: 95%;
  }
}
@media (max-width: 640px) {
  .postLikeProfileImgs img {
    width: 40px;
    height: 40px;
  }
  .postProfileImgMobile {
    width: 60px;
    height: 60px;
  }
  .postPlayerIdMobile {
    font-size: 15px;
  }
}
@media (max-width: 600px) {
  .postDateMobile {
    font-size: 15px;
  }
  .postRePostCounter {
    font-size: 20px;
  }
  .postViewsMobile {
    font-size: 15px;
  }
  .viewCommentsMobile {
    font-size: 20px;
  }
}
@media (max-width: 578px) {
  .postPlayerIdMobile {
    font-size: 25px;
  }
  .postDateMobile {
    font-size: 22px;
  }
  .likeIconMobile {
    width: 40px;
    height: 40px;
  }
  .viewCommentsMobile {
    font-size: 22px;
  }
  .postLikeProfileImgs img {
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 540px) {
  .postViewsMobile {
    font-size: 20px;
    font-weight: 400;
    margin: 0 10px;
    transform: translateY(0rem);
  }
  .postPlayerIdMobile {
    font-size: 20px;
  }
  .pogwTitleSpanMobile {
    font-size: 20px;
  }
  .postDateMobile {
    font-size: 20px;
  }
  .postJGKAmount {
    font-size: 20px;
  }
}

@media (max-width: 520px) {
  .likeIconMobile {
    width: 30px;
    height: 30px;
  }
  .postDateMobile {
    font-size: 20px;
  }
}
@media (max-width: 480px) {
  .postRePostCounter {
    font-size: 15px;
  }
}

@media (max-width: 440px) {
  .likeIconMobile {
    width: 25px;
    height: 25px;
  }
  .postDateMobile {
    font-size: 14px;
  }
  .viewCommentsMobile {
    font-size: 15px;
  }
  .postLikeProfileImgs img {
    width: 25px;
    height: 25px;
  }
  .postViewsMobile {
    font-size: 14px;
    font-weight: 400;
    margin: 0 6px;
    transform: translateY(-0.1rem);
  }
}
@media (max-width: 414px) {
  .pogwIcon {
    height: 22px;
    width: 22px;
  }
  .pogwTitleSpanMobile {
    font-size: 13px;
  }
  .postJGKBadge {
    transform: translate(113px, -11px);
  }
  .postJGKAmount {
    font-size: 13px;
    transform: translate(58px, -11px);
  }
}
@media (max-width: 412px) {
  .postRePostCounter {
    font-size: 15px;
  }
}

/* @media (max-width: 440px) {
  .postDateMobile {
    font-size: 14px;
  }
} */
@media (max-width: 384px) {
  .postRePostCounter {
    font-size: 15px;
  }
}
@media (max-width: 375px) {
  .pogwTitleSpanMobile {
    font-size: 13px;
  }
  .postJGKBadge {
    transform: translate(113px, -11px);
  }
  .postJGKAmount {
    font-size: 13px;
    transform: translate(58px, -11px);
  }
  .postDateMobile {
    font-size: 14px;
  }
  .postPlayerIdMobile {
    font-size: 14px;
  }
  .postViewsMobile {
    font-size: 13px;
    font-weight: 400;
    margin: 0 6px;
    transform: translateY(-0.1rem);
  }
}
@media (max-width: 360px) {
  .postDateMobile {
    font-size: 12px;
  }
  .postRePostCounter {
    font-size: 15px;
  }
}
@media (max-width: 280px) {
  .likeIconMobile {
    width: 20px;
    height: 20px;
  }
  .postDateMobile {
    font-size: 12px;
  }
  .viewCommentsMobile {
    font-size: 10px;
  }
  .postLikeProfileImgs img {
    width: 23px;
    height: 25px;
  }
  .postDateMobile {
    font-size: 10px;
  }
  .postViewsMobile {
    font-size: 10px;
    font-weight: 400;
    margin: 0 6px;
    transform: translateY(-0.19rem);
  }
  .pogwIcon {
    height: 20px;
    width: 20px;
  }
  .postNotificationIconBadgeJGK {
    transform: translate(-110px, 13px);
    font-size: 10px;
  }
  .pogwTitleSpanMobile {
    font-size: 10px;
  }
  .postTopRightPOGW {
    transform: translate(-21px, -11px);
  }

  .postTopRight {
    transform: translate(6px, -11px);
  }
  .postJGKAmount {
    font-size: 10px;
    transform: translate(50px, -11px);
  }
  .postJGKBadge {
    transform: translate(100px, -11px);
    font-size: 15px;
  }
  .postJgkdCounterMobile {
    font-size: 10px;
  }
  .sendJGKToPostAmountLabel {
    font-size: 12px;
  }
  .sendOutlinedIcon {
    padding-left: 0px;
  }
}

/* @media (max-width: 805px) {
  .postLikeCounter,
  .postCenter,
  .postComment,
  .postPlayerId,
  .commentText {
    font-size: 24px;
  }
} */
