.conversation {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  margin-top: 20px;
}

.conversation:hover {
  background-color: rgb(245, 243, 243);
}
.conversationDark {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  margin-top: 20px;
}

.conversationDark:hover {
  background-color: #444;
}

.conversationImg {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
  pointer-events: none;
}
.conversationImgMobile {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
  pointer-events: none;
}

.conversationOnlineBadge {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: limegreen;
  position: absolute;
  top: 2px;
  right: 25px;
}
.conversationOnlineBadgeMobile {
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background-color: limegreen;
  position: absolute;
  top: 2px;
  right: 15px;
}

.unReadMessagesIconBadge {
  width: 15px;
  height: 15px;
  background-color: red;
  border-radius: 50%;
  color: white;
  position: absolute;
  top: 30px;
  right: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

.conversationName {
  font-weight: 500;
  font-size: 16px;
}
.conversationNameMobile {
  font-weight: 500;
  font-size: 29px;
}

@media (max-width: 805px) {
  .conversationImgMobile {
    width: 60px;
    height: 60px;
  }
  .conversationNameMobile {
    font-size: 25px;
  }
  /* .conversation {
    margin-top: 60px;
  } */
}

@media (max-width: 768px) {
  .conversationName {
    display: none;
  }
  .conversationOnlineBadgeMobile {
    right: 20px;
    width: 20px;
    height: 20px;
  }
}

@media (max-width: 649px) {
  .conversationNameMobile {
    display: none;
  }
}

@media (max-width: 540px) {
  .conversationOnlineBadgeMobile {
    right: 24px;
    width: 20px;
    height: 20px;
  }
  .chatBoxMobile {
    width: 60%;
  }
}

@media (max-width: 487px) {
  .conversationImgMobile {
    width: 50px;
    height: 50px;
  }
}

@media (max-width: 416px) {
  .conversationImgMobile {
    width: 40px;
    height: 40px;
  }
}

@media (max-width: 414px) {
  .conversationOnlineBadgeMobile {
    right: 20px;
    width: 15px;
    height: 15px;
  }
  .chatBoxMobile {
    width: 54%;
  }
  .unReadMessagesIconBadge {
    width: 15px;
    height: 15px;
    top: 25px;
    right: 19px;
    font-size: 11px;
  }
}

@media (max-width: 390px) {
  .chatBoxMobile {
    width: 52%;
  }
}
@media (max-width: 393px) {
  .conversationImgMobile {
    width: 35px;
    height: 35px;
  }
  .chatBoxMobile {
    width: 52%;
  }
}
@media (max-width: 375px) {
  .conversationOnlineBadgeMobile {
    right: 19px;
    width: 13px;
    height: 13px;
  }
  .chatBoxMobile {
    width: 50%;
  }
  .unReadMessagesIconBadge {
    width: 13px;
    height: 13px;
    top: 22px;
    right: 19px;
    font-size: 11px;
  }
}

@media (max-width: 360px) {
  .conversationImgMobile {
    width: 30px;
    height: 30px;
  }
  .chatBoxMobile {
    width: 47%;
  }
  .unReadMessagesIconBadge {
    top: 20px;
    right: 19px;
  }
}
@media (max-width: 280px) {
  .conversationImgMobile {
    width: 25px;
    height: 25px;
  }
  .conversationOnlineBadgeMobile {
    width: 11px;
    height: 11px;
  }
  .chatBoxMobile {
    width: 53%;
  }
  .unReadMessagesIconBadge {
    width: 11px;
    height: 11px;
    top: 17px;
    right: 18px;
    font-size: 10px;
  }
}
