.meme {
  width: 97%;
  border-radius: 10px;
  /* -webkit-box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68); */
  margin: 30px 5px;
}
.memeDarkTheme {
  width: 97%;
  border-radius: 10px;
  /* -webkit-box-shadow: 0px 0px 16px -8px rgba(255, 255, 255, 0.68);
  box-shadow: 0px 0px 16px -8px rgba(255, 255, 255, 0.68); */
  margin: 30px 5px;
}

.memeContainerElement {
  /* height: 300px; */
  height: auto;
  width: 300px;
  border-radius: 10px;
  margin: 30px 0;
}

.meme-img {
  /* set img elements to be responsive */
  height: auto;
  width: 100%;
  border-radius: 10px;
  /* [optional] To stretch image to fit container */
  object-fit: cover;
}

.memeImg2 {
  margin-top: 15px;
  width: 100%;
  /* max-height: 500px; */
  object-fit: contain;
  display: block;
  margin: 0 auto;
  /* pointer-events: none; */
}

/* .rightbarAd {
      width: 100%;
      border-radius: 10px;
      margin: 30px 0;
    } */

.circleProgress {
  align-self: center;
}

.memeByName {
  /* position: absolute;
      right: 40px; */
}

.memeByNameContainer {
  width: 300px;
  height: auto;
  display: flex;
}

.profileLink:link,
active {
  text-decoration: none;
  font-size: 12px;
}

.memePostToggleMeme {
  /* display: flex;
  align-items: center;
  gap: 10px;
  align-items: center;
  justify-content: center; */
  padding-bottom: 10px;
}
.memeBottom {
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.memeBottomLeft {
  display: flex;
  align-items: center;
}

.likeIcon {
  width: 24px;
  height: 24px;
  margin-right: 5px;
  cursor: pointer;
}

.memeLikeCounter {
  font-size: 15px;
}

.memeCommentText {
  cursor: pointer;
  font-size: 10px;
}

.imgVidContainer {
  width: 100%;
}

.memeViews {
  font-size: 13px;
  font-weight: 400;
  margin: 0 10px;
  transform: translate(0px, -2px);
}
.memeViewsMobile {
  font-size: 13px;
  font-weight: 400;
  margin: 0 10px;
  transform: translateY(-0.19rem);
}

@media (max-width: 800px) {
  .memeByNameContainer {
    width: 100%;
  }
  .memeByName {
    font-size: 20px;
  }
  .memeViewsMobile {
    font-size: 20px;
    transform: translateY(0rem);
  }
  .postDateMobile {
    font-size: 20px;
  }
  .memeBottom {
    margin-top: 10px;
  }
  .memeLikeCounter {
    font-size: 20px;
  }
}
@media (max-width: 640px) {
  .memeByName {
    font-size: 15px;
  }
  .memeViewsMobile {
    font-size: 15px;
  }
  .postDateMobile {
    font-size: 15px;
  }
  .memeLikeCounter {
    font-size: 15px;
  }
}
@media (max-width: 384px) {
  .postDateMobile {
    font-size: 15px;
  }
  .postViewsMobile {
    font-size: 14px;
  }
}
