.chatOnlineFriend {
  display: flex;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
  margin-top: 10px;
}
.chatOnlineFriendMobile {
  display: flex;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
  margin-top: 10px;
}

.chatOnlineImgContainer {
  position: relative;
  margin-right: 10px;
}

.chatOnlineImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  /* border: 1px solid white; */
}
.chatOnlineImgMobile {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  /* border: 1px solid white; */
}

.chatOnlineBadge {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: limegreen;
  position: absolute;
  top: 2px;
  right: 2px;
}
.chatOnlineBadgeMobile {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: limegreen;
  position: absolute;
  top: 2px;
  right: 2px;
}

.chatOnlineName {
  font-size: 16px;
}
.chatOnlineNameMobile {
  font-size: 29px;
}

/* @media screen and (max-width: 768px) {
  .chatOnlineName {
    display: none;
  }
} */
@media (max-width: 805px) {
  .chatOnlineImgMobile {
    width: 60px;
    height: 60px;
  }
  .chatOnlineFriendMobile {
    margin-top: 65px;
  }
  .chatOnlineNameMobile {
    font-size: 20px;
  }
}

@media (max-width: 773px) {
  .chatOnlineNameMobile {
    display: none;
  }
}

@media (max-width: 487px) {
  .chatOnlineImgMobile {
    width: 50px;
    height: 50px;
  }
}

@media (max-width: 416px) {
  .chatOnlineImgMobile {
    width: 40px;
    height: 40px;
  }
  .chatOnlineBadgeMobile {
    width: 15px;
    height: 15px;
  }
}
@media (max-width: 393px) {
  .chatOnlineImgMobile {
    width: 35px;
    height: 35px;
  }
}

@media (max-width: 360px) {
  .chatOnlineImgMobile {
    width: 28px;
    height: 28px;
  }
  .chatOnlineBadgeMobile {
    width: 12px;
    height: 12px;
  }
}
@media (max-width: 280px) {
  .chatOnlineImgMobile {
    width: 25px;
    height: 25px;
  }
}
