.btc_wrapper {
  background: var(--bg-primary);
  -webkit-box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  padding: 30px;
  font-size: 14px;
  color: var(--color-primary);
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  margin-bottom: -60px;
}

.btc_priceVal {
  font-size: 20px;
}
.btc_priceValMobile {
  font-size: 40px;
}
@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.shake {
  animation: shake 0.5s;
}
.btc_price {
  display: flex;
  margin-top: 5px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  /* width: auto; */
  font-size: 20px;
}
.btc_priceMobile {
  display: flex;
  margin-top: 5px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  width: 60%;
  /* font-size: 40px; */
}

.btc_Img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  pointer-events: none;
  /* margin-right: 40px; */
}
/* .btc_priceMobile {
  display: flex;
  margin-top: 5px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  width: 100%;
  font-size: 20px;
} */

.btc_ImgMobile {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  pointer-events: none;
  margin-right: 40px;
}

@media (max-width: 979px) {
  .btc_wrapper {
    width: 93%;
  }
}

@media (max-width: 805px) {
  /* .btc_h1 {
    width: 100%;
  } */
  .btc_wrapper {
    width: 91%;
  }
  .btc_priceValMobile {
    font-size: 30px;
  }
}

@media (max-width: 690px) {
  .btc_priceValMobile {
    font-size: 20px;
  }

  .btc_ImgMobile {
    width: 60px;
    height: 60px;
  }
  .profileCoverImg {
    width: 87%;
  }
}

@media (max-width: 578px) {
  .btc_priceValMobile {
    font-size: 18px;
  }
  .btc_wrapper {
    width: 89%;
  }
  .btc_ImgMobile {
    width: 60px;
    height: 60px;
  }
}

@media (max-width: 520px) {
  .btc_priceValMobile {
    font-size: 15px;
  }
  .btc_wrapper {
    width: 87%;
  }
  .btc_ImgMobile {
    width: 45px;
    height: 45px;
  }
  .btc_priceMobile {
    gap: 22px;
  }
}
@media (max-width: 440px) {
  .btc_priceValMobile {
    font-size: 14px;
  }
  .btc_wrapper {
    width: 83%;
  }
  .btc_ImgMobile {
    width: 35px;
    height: 35px;
  }
  .btc_priceMobile {
    gap: 10px;
  }
}
@media (max-width: 361px) {
  .btc_priceValMobile {
    font-size: 13px;
  }
  .btc_wrapper {
    width: 83%;
  }
  .btc_ImgMobile {
    width: 30px;
    height: 30px;
  }
  .btc_priceMobile {
    gap: 10px;
  }
}
@media (max-width: 320px) {
  .btc_wrapper {
    padding: 24px;
    margin-bottom: -47px;
  }
  .btc_ImgMobile {
    width: 23px;
    height: 23px;
  }
  .btc_priceValMobile {
    font-size: 12px;
  }
  .btc_priceMobile {
    gap: 6px;
  }
  .postRePostCounter {
    font-size: 14px;
  }
  .commentTextMobile {
    font-size: 13px;
  }
}
@media (max-width: 280px) {
  .btc_priceValMobile {
    font-size: 9px;
  }
  .btc_wrapper {
    width: 77%;
  }
  .btc_ImgMobile {
    width: 30px;
    height: 30px;
  }
  .btc_priceMobile {
    gap: 5px;
  }
}
