.memeRotatorContainerElement {
  /* height: 300px; */
  height: auto;
  width: 300px;
  border-radius: 10px;
  margin: 30px 0;
}

.memeRotatorContainerElementMobile {
  /* height: 300px; */
  height: auto;
  width: 373px;
  border-radius: 10px;
  margin: 30px 0;
}

.memeRotator-img {
  /* set img elements to be responsive */
  height: 100%;
  width: 100%;
  border-radius: 10px;
  /* [optional] To stretch image to fit container */
  object-fit: cover;
}
/* .rightbarAd {
    width: 100%;
    border-radius: 10px;
    margin: 30px 0;
  } */

.circleProgress {
  align-self: center;
}

/* @media (max-width: 1024px) {
  .memeRotatorContainerElement {
    width: 860px;
  }
} */

@media (max-width: 912px) {
  .memeRotatorContainerElement {
    width: 860px;
  }
}

@media (max-width: 820px) {
  .memeRotatorContainerElement {
    width: 765px;
  }
}
@media (max-width: 800px) {
  .memeRotatorContainerElementMobile {
    width: 760px;
  }
}

@media (max-width: 768px) {
  .memeRotatorContainerElementMobile {
    width: 731px;
  }
}
@media (max-width: 712px) {
  .memeRotatorContainerElementMobile {
    width: 665px;
  }
  .postDateMobile {
    font-size: 15px;
  }
  .postLikeCounterMobile {
    font-size: 15px;
  }
  .commentNameMobile {
    font-size: 20px;
  }
  .commentTextMobile {
    font-size: 20px;
  }
}
@media (max-width: 640px) {
  .memeRotatorContainerElementMobile {
    width: 600px;
  }
}

@media (max-width: 600px) {
  .memeRotatorContainerElementMobile {
    width: 560px;
  }
}

@media (max-width: 540px) {
  .memeRotatorContainerElementMobile {
    width: 503px;
  }
}
@media (max-width: 480px) {
  .memeRotatorContainerElementMobile {
    width: 442px;
  }
  .commentTextMobile {
    font-size: 15px;
  }
}
@media (max-width: 430px) {
  .memeRotatorContainerElementMobile {
    width: 395px;
  }
  .commentNameMobile {
    font-size: 15px;
  }
}
@media (max-width: 414px) {
  .memeRotatorContainerElementMobile {
    width: 372px;
  }
  .commentNameMobile {
    font-size: 15px;
  }
}
@media (max-width: 412px) {
  .commentTextMobile {
    font-size: 15px;
  }
  .commentNameMobile {
    font-size: 15px;
  }
}
@media (max-width: 393px) {
  .memeRotatorContainerElementMobile {
    width: 351px;
  }
}

@media (max-width: 390px) {
  .memeRotatorContainerElementMobile {
    width: 348px;
  }
}
@media (max-width: 384px) {
  .commentTextMobile {
    font-size: 15px;
  }
  .commentNameMobile {
    font-size: 15px;
  }
  .postLikeCounterMobile {
    font-size: 15px;
  }
}
@media (max-width: 375px) {
  .memeRotatorContainerElementMobile {
    width: 338px;
  }
}
@media (max-width: 360px) {
  .memeRotatorContainerElementMobile {
    width: 318px;
  }
}

@media (max-width: 320px) {
  .memeRotatorContainerElementMobile {
    width: 283px;
  }
  .postLikeCounterMobile {
    font-size: 11px;
  }
  .commentTextMobile {
    font-size: 13px;
  }
}

@media (max-width: 280px) {
  .memeRotatorContainerElementMobile {
    width: 243px;
  }
}
