.message {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.xButton {
  width: 20px; /* Size of the button */
  height: 20px; /* Size of the button */
  border-radius: 50%; /* Rounded corners */
  font-size: 14px; /* Adjust based on the size of the button */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none; /* Remove border */
  background-color: rgb(245, 241, 241);
  color: black; /* Color of the 'X' */
  transform: translateY(8px);
}
.xButtonDark {
  width: 20px; /* Size of the button */
  height: 20px; /* Size of the button */
  border-radius: 50%; /* Rounded corners */
  font-size: 14px; /* Adjust based on the size of the button */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none; /* Remove border */
  background-color: black;
  color: white; /* Color of the 'X' */
  transform: translateY(8px);
}
.msgAndXBtnContainer {
  display: flex;
  align-items: center;
}
.messageTop {
  display: flex;
  /* margin: auto; */
  margin-left: 4px;
  flex-direction: row;
}

.messageTopImgVid {
  display: flex;
  /* margin: auto; */
  margin-top: 10px;
}

.messageTopMobile {
  display: flex;
  margin: auto;
}

.messageImg {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}
.messageImgTransform {
  transform: translateY(0.68rem);
}
.messageImgMobile {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.messageCenter {
  margin: 0px 0;
}

.messageImg2 {
  margin-top: 15px;
  margin-left: 4px;
  width: 80%;
  max-width: 98%;
  /* max-height: 500px; */
  object-fit: contain;
  display: block;
  margin: 0 auto;
  /* pointer-events: none; */
}
/* .modal-image {
  width: 100% !important;
} */
.messageVid {
  margin-top: 15px;
  width: 100%;
  /* max-height: 500px; */
  object-fit: contain;
}

.messageText {
  padding: 10px;
  border-radius: 20px;
  background-color: #8a2be2;
  color: white;
  max-width: 300px;
  white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
  white-space: -webkit-pre-wrap; /* Chrome & Safari */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  white-space: pre-wrap; /* CSS3 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  word-break: keep-all;
  white-space: normal;
}
.messageTextMobile {
  padding: 10px;
  border-radius: 20px;
  background-color: #8a2be2;
  color: white;
  max-width: 300px;
  white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
  white-space: -webkit-pre-wrap; /* Chrome & Safari */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  white-space: pre-wrap; /* CSS3 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  word-break: keep-all;
  white-space: normal;
}
.messageTextNotOwn {
  padding: 10px;
  border-radius: 20px;
  background-color: #8a2be2;
  color: white;
  max-width: 300px;
  white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
  white-space: -webkit-pre-wrap; /* Chrome & Safari */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  white-space: pre-wrap; /* CSS3 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  word-break: keep-all;
  white-space: normal;
  width: fit-content;
}
.messageTextMobileNotOwn {
  padding: 10px;
  border-radius: 20px;
  background-color: #8a2be2;
  color: white;
  max-width: 300px;
  white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
  white-space: -webkit-pre-wrap; /* Chrome & Safari */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  white-space: pre-wrap; /* CSS3 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  word-break: keep-all;
  white-space: normal;
}

.messageText a:link,
.messageText a:visited {
  color: #333;
}
.messageTextMobile a:link,
.messageTextMobile a:visited {
  color: #333;
}
.messageTextNotOwn a:link,
.messageText a:visited {
  color: white;
}
.messageTextMobileNotOwn a:link,
.messageTextMobileNotOwn a:visited {
  color: white;
}

.messageText a:hover,
.messageText a:active {
  color: #333;
}
.messageTextMobile a:hover,
.messageTextMobile a:active {
  color: #333;
}

.messageBottom {
  font-size: 12px;
  margin-top: 10px;
}
.messageBottomMobile {
  font-size: 27px;
  margin-top: 10px;
}
.messageBottomMsgVideo {
  font-size: 12px;
  margin-top: 10px;
}
.messageBottomMobileMsgVideo {
  font-size: 13px;
  margin-top: 0px;
}

.message.own {
  align-items: left;
}

.message.own .messageText {
  background-color: rgb(245, 241, 241);
  color: #333;
}
.message.own .messageTextMobile {
  background-color: rgb(245, 241, 241);
  color: #333;
}

.message.own .messageText a:link,
.message.own .messageText a:visited {
  color: #333;
}

.message.own .messageText a:hover,
.message.own .messageText a:active {
  color: #8a2be2;
}

.lqip {
  background-repeat: no-repeat;
  background-size: cover;
}

.loadingImg {
  background: transparent
    url(https://res.cloudinary.com/joegalaxy/image/upload/v1672710329/image-loading-loading_ncznsu.gif)
    center center no-repeat;
}

@media (max-width: 805px) {
  .messageImgMobile {
    width: 60px;
    height: 60px;
  }
  .messageTextMobile {
    font-size: 30px;
  }
  .messageTextMobileNotOwn {
    font-size: 30px;
  }
  .messageBottomMobile {
    font-size: 20px;
  }
}
@media (max-width: 684px) {
  .messageTextMobile {
    max-width: 200px;
  }
  .messageTextMobileNotOwn {
    max-width: 200px;
  }
}
@media (max-width: 487px) {
  .messageImgMobile {
    width: 50px;
    height: 50px;
  }
  .messageTextMobile {
    font-size: 22px;
    max-width: 120px;
  }
  .messageTextMobileNotOwn {
    font-size: 22px;
    max-width: 120px;
  }
  .messageBottomMobile {
    font-size: 17px;
  }
  /* .chatBoxMobile {
    width: 75%;
  } */
}
@media (max-width: 480px) {
  .messageTextMobileNotOwn {
    font-size: 15px;
  }
  .messageTextMobile {
    font-size: 15px;
  }
}
@media (max-width: 416px) {
  .messageImgMobile {
    width: 35px;
    height: 35px;
  }
  .messageTextMobile {
    font-size: 15px;
    max-width: 80px;
  }
  .messageTextMobileNotOwn {
    font-size: 15px;
    max-width: 80px;
  }
  .messageBottomMobile {
    font-size: 13px;
  }
}

@media (max-width: 385px) {
  .messageTextMobile {
    font-size: 15px;

    max-width: 76px;
  }
}
@media (max-width: 360px) {
  .messageImgMobile {
    width: 28px;
    height: 28px;
  }
}
@media (max-width: 320px) {
  .messageBottomMobile {
    font-size: 10px;
  }
  .viewMessagesMobile {
    font-size: 11px;
  }
  .messageTextMobileNotOwn {
    font-size: 11px;
    max-width: 55px;
  }
  .messageTextMobile {
    font-size: 10px;
    max-width: 52px;
  }
}
@media (max-width: 280px) {
  .messageTextMobile {
    max-width: 53px;
  }
  .messageTextMobileNotOwn {
    max-width: 53px;
  }
  .messageImgMobile {
    width: 25px;
    height: 25px;
  }
}
@media (max-width: 240px) {
  .messageTextMobile {
    max-width: 28px;
  }
  .messageTextMobileNotOwn {
    max-width: 28px;
  }
  .messageImgMobile {
    width: 25px;
    height: 25px;
  }
}
