.full {
  width: 99vw;
  height: 90vh;
  margin: auto;
  border: 3px solid #73ad21;
}

.marker-btn {
  background: none;
  border: none;
  cursor: pointer;
}
.marker-btn img {
  width: 35px;
  height: 55px;
}

.marker-btn2 {
  background: none;
  border: none;
  cursor: pointer;
}

.marker-btn2 img {
  background: none;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  /* pointer-events: none; */
}
.distanceInputPlayerMap {
  width: 100%;
  height: 30px;
  background-color: white;
  border-radius: 30px;
  display: flex;
  align-items: center;
}
.marker-btn2 {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.post-preview {
  width: 100px;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border: 2px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
}

.post-preview img,
.post-preview video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.no-underline {
  text-decoration: none;
}
.centered-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.post-small {
  width: 50px;
  height: 70px;
  overflow: hidden;
  position: relative;
}

.post-small img,
.post-small video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.popup-inner {
  border-radius: 8px;
  padding: 10px;
}

.popup-inner.darkTheme {
  background-color: black;
  color: white;
}
.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding-bottom: 5px; */
}
.padding-bottom {
  padding-bottom: 5px;
}
.black-link {
  color: black;
  text-decoration: none;
}

.black-link:visited {
  color: black !important;
}
