.subscriberCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}
.subscriberPhotoWrapper {
  position: relative;
}
.subscriberPhoto {
  width: 80px; /* adjust as needed */
  height: 80px; /* adjust as needed */
  border-radius: 10px; /* adjust as needed */
  /* other styles */
}
.galaxyOnlineBadge {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: limegreen;
  position: absolute;
  top: 2px;
  right: 7px;
}
.galaxyOnlineBadgeMobile {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: limegreen;
  position: absolute;
  top: 2px;
  right: 9px;
}
.galaxyChatOnlineBadgeMobile {
  position: absolute;
  /* top: 2px;
  left: 2px; */
  bottom: 6px;
  right: 9px;
  width: 10px;
  height: 10px;
  background-color: #8a2be2;
  border-radius: 50%;
}

.galaxyChatOnlineBadge {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 15px;
  height: 15px;
  background-color: #8a2be2;
  border-radius: 50%;
}
.rightBarTitle {
  margin-top: 20px; /* Adjust as needed */
}
.subscriberInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.subscriberId {
  margin-top: 10px;
}

.subscriberTokens {
  margin-top: 5px;
}
.highlighted {
  background-color: limegreen;
  border-radius: 5px;
}
.galaxyRightBar {
  flex: 3.6;

  /* other styles */
}
@media (min-width: 600px) {
  .galaxyRightBar {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
}

@media (min-width: 900px) {
  .galaxyRightBar {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}
/* .galaxyRightBar,
.subscriberCard,
.subscriberPhoto,
.subscriberInfo {
  margin: 0;
  padding: 0;
} */
