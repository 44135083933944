.cashoutGiftCard {
  padding: 5px;
}
.sendJGKToPlayer {
  padding: 5px;
}
.cashoutJGKToGiftCardLabel {
  padding-left: 5px;
}
.sendJGKToPlayerComponent {
  width: 100%;
}
.sendJGKToPlayerAmountInputs {
  padding: 11px;
  border-radius: 10px;
  margin-left: -16px;
}
.sendJGKToPlayerSelect {
  padding: 5px;
}
.jgkFeeSpan {
  margin-left: 5px;
}
.sendJGKSubmitButton {
  /* width: 70px;
    height: 40px; */
  /* padding: 1px; */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #8a2be2;
  color: white;
  margin-left: 5px;
}
.sendJGKSubmitButtonDisabled {
  /* width: 70px;
    height: 40px; */
  /* padding: 1px; */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #450b7a;
  color: white;
  margin-left: 5px;
}
.giftCardAmountInput {
  width: 27%;
}
.giftCardIsSelectedDetails {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
}
.giftCardSelect {
  width: 39%;
}
.selectedAmountButtonActive {
  /* width: 70px;
    height: 40px; */
  /* padding: 1px; */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #8a2be2;
  color: white;
  margin-left: 5px;
}
.unSelectedAmountButton {
  /* width: 70px;
    height: 40px; */
  /* padding: 1px; */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #450b7a;
  color: white;
  margin-left: 5px;
  padding: 1px;
}
.giftCardSelectOrderFee {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding-top: 5px;
}
.giftCardSelectAnAmount {
  padding-left: 34px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  padding-top: 5px;
}
.cashoutGiftCardCheckBox {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding-right: 165px;
}

.error_textCashoutJGKToken {
  color: #b94a48;
  width: 30%;
}

@media (max-width: 820px) {
  .giftCardSelect {
    width: 51%;
  }
  .cashoutGiftCardCheckBox {
    padding-right: 191px;
  }
  .giftCardSelectAnAmount {
    padding-left: 41px;
  }
  .giftCardSelectOrderFee {
    justify-content: center;
  }
}
@media (max-width: 768px) {
  .giftCardSelect {
    width: 51%;
  }
  .cashoutGiftCardCheckBox {
    padding-right: 191px;
  }
  .giftCardSelectAnAmount {
    padding-left: 70px;
  }
  .giftCardSelectOrderFee {
    justify-content: center;
  }
}
@media (max-width: 540px) {
  .giftCardSelect {
    width: 51%;
  }
  .cashoutGiftCardCheckBox {
    padding-right: 144px;
  }
  .giftCardSelectAnAmount {
    padding-left: 51px;
  }
  .giftCardSelectOrderFee {
    justify-content: center;
  }
}

@media (max-width: 414px) {
  .giftCardSelect {
    width: 51%;
  }
  .cashoutGiftCardCheckBox {
    padding-right: 100px;
  }
  .giftCardSelectAnAmount {
    padding-left: 25px;
  }
  .giftCardSelectOrderFee {
    justify-content: center;
  }
}
@media (max-width: 393px) {
  .giftCardSelect {
    width: 51%;
  }
  .cashoutGiftCardCheckBox {
    padding-right: 82px;
  }
  .giftCardSelectAnAmount {
    padding-left: 22px;
  }
}

@media (max-width: 390px) {
  .giftCardSelect {
    width: 51%;
  }
  .cashoutGiftCardCheckBox {
    padding-right: 83px;
  }
  .giftCardSelectAnAmount {
    padding-left: 21px;
  }
  .giftCardSelectOrderFee {
    justify-content: center;
  }
}
@media (max-width: 375px) {
  .giftCardSelect {
    width: 51%;
  }
  .cashoutGiftCardCheckBox {
    padding-right: 100px;
  }
  .giftCardSelectAnAmount {
    padding-left: 3px;
  }
  .giftCardSelectOrderFee {
    justify-content: center;
  }
}
@media (max-width: 360px) {
  .giftCardSelect {
    width: 51%;
  }
  .cashoutGiftCardCheckBox {
    padding-right: 94px;
  }
  .giftCardSelectAnAmount {
    padding-left: 0px;
  }
  .giftCardSelectOrderFee {
    justify-content: center;
  }
}
@media (max-width: 320px) {
  .giftCardSelectAnAmount {
    padding-left: 10px;
  }
}
@media (max-width: 280px) {
  .giftCardSelect {
    width: 51%;
  }
  .cashoutGiftCardCheckBox {
    padding-right: 2px;
  }
  .giftCardSelectAnAmount {
    padding-left: 24px;
  }
  .giftCardSelectOrderFee {
    justify-content: center;
  }
}
